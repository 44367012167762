<template>
  <v-card flat>
    <v-card flat :style="'height: ' + (height - 158) + 'px; overflow: auto'">
      <!-- Indicator name -->
      <v-container v-if="loadingIndicators" class="px-0 py-6">
        <v-skeleton-loader
          class="py-0 my-0"
          width="100%"
          type="list-item-two-line"
        ></v-skeleton-loader>
      </v-container>
      <v-card v-else class="pa-4" flat>
        <v-card-actions class="subheader text-left">
          <div style="width: 80%">
            {{ selectedIndicator.indicator_name }}
          </div>
          <v-spacer />
          <span>
            <DiscoveryToolSelectIndicator
              v-model:selectedIndicatorProp="selectedIndicator"
              :indicators="indicators"
            />
          </span>
        </v-card-actions>
      </v-card>
      <v-divider></v-divider>

      <v-card flat class="pa-0">
        <v-skeleton-loader
          v-if="loadingIndicators"
          type="list-item-two-line"
          class="px-1 py-0 pb-0"
        />
      </v-card>

      <!-- Indicator legend -->
      <v-card class="px-4 pt-4 pb-0" flat>
        <div v-if="!loadingIndicators" class="text-left px-2 pb-4">
          {{ selectedIndicator.indicator_legend }}
        </div>
        <v-card-actions>
          <!-- data display level dropdown -->
          <MapDataLevelSelect
            :selectedDataLevelId="viewInfo.viewportInfo.dataLevel"
            :lowestDataLevelId="viewInfo.indicatorInfo.lowest_show_level"
            :lockedView="lockedView"
            :loading="loadingIndicators"
            loadingStyle="skeleton"
            @selectedDataLevelChanged="
              (newLevel) => this.$emit('selectedDataLevelChanged', newLevel)
            "
            @lockViewChanged="(state) => this.$emit('lockViewChanged', state)"
          ></MapDataLevelSelect>
        </v-card-actions>
        <v-card-text
          v-if="viewInfo.quintiles.q1_min === 'loading'"
          class="loadingQuintiles pl-0 pt-0"
        >
          <v-skeleton-loader v-for="n in 5" :key="n" type="text" width="50%" />
        </v-card-text>
        <v-card-actions v-else>
          <!-- quintiles -->
          <div class="d-flex mb-2">
            <MapQuintiles :viewInfo="viewInfo" :colourScheme="colourScheme" />
          </div>
        </v-card-actions>
        <!-- Details button -->
        <v-card-actions class="justify-center">
          <v-btn
            variant="elevated"
            color="primary"
            width="80%"
            @click="this.$emit('closeMap')"
            >go back
          </v-btn>
        </v-card-actions>

        <!--  Display data switcher  -->
        <v-card-actions class="displayDataSwitcher">
          <v-switch
            label="Display data visualisations outside area boundaries"
            color="black"
          />
        </v-card-actions>
      </v-card>
      <v-divider></v-divider>

      <!-- Values -->
      <v-card height="50px" class="d-flex pt-3 px-5" flat>
        <div class="text-start">
          <b>{{ levelNameAbr }} </b> {{ areaName ? areaName : "None" }}
        </div>
        <v-spacer></v-spacer>
        <div class="text-start"><b>Value: </b> {{ getAreaValue(value) }}</div>
      </v-card>
      <v-divider></v-divider>

      <!-- Areas of interest -->
      <v-card
        class="pa-4"
        flat
        max-height="60px"
        style="display: flex; align-items: center"
      >
        <span class="subheader">Areas of interest</span>
        <v-spacer />
        <span>
          <v-icon class="ml-2" icon="mdi-pencil" />
        </span>
      </v-card>
      <v-divider></v-divider>

      <!-- Expansion panels -->
      <v-card flat style="height: 23.5vh; overflow: auto">
        <!-- Double disadvantaged areas -->
        <v-expansion-panels class="expandAreas">
          <v-expansion-panel>
            <v-expansion-panel-title>
              <template #default="{}">
                Double disadvantaged areas
                {{
                  areasOfInterest.disadvantaged?.length
                    ? ` (${areasOfInterest.disadvantaged.length})`
                    : ""
                }}</template
              >
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <!-- Loading  -->
              <v-container v-if="loadingAreasOfInterest" class="pa-0">
                <v-skeleton-loader
                  v-for="n in 3"
                  :key="n"
                  class="mx-auto border"
                  width="100%"
                  type="list-item-three-line"
                ></v-skeleton-loader>
              </v-container>
              <v-card
                v-else
                v-for="area in areasOfInterest.others"
                flat
                :key="area.id"
                class="pa-0"
                min-height="80px"
                style="
                  border-top: solid gray 1px;
                  border-bottom: solid gray 1px;
                "
              >
                <v-card-actions>
                  <v-card-actions class="pa-0 mr-1" style="min-width: 15%">
                    <v-checkbox color="primary"></v-checkbox>
                  </v-card-actions>
                  <v-card-actions
                    class="px-0 py-2 text-left"
                    style="width: 75%"
                  >
                    <div>
                      {{ area.area_display_name || area.area_name }}
                      <div class="d-flex flex-row mt-2" style="width: 100%">
                        <div class="flex-row" style="width: 50%">
                          <v-icon color="red" class="mr-2"> mdi-square </v-icon>
                          <b>CNI: </b> {{ area.cmn_value }}
                        </div>
                        <div class="flex-row" style="width: 50%">
                          <v-icon color="green" class="mr-2">
                            mdi-square
                          </v-icon>
                          <b>IoD: </b> {{ area.imd_value }}
                        </div>
                      </div>
                    </div>
                  </v-card-actions>
                  <v-spacer />
                  <v-card-actions class="pa-0 mr-3">
                    <v-icon icon="mdi-magnify-plus-outline"></v-icon>
                  </v-card-actions>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- Other areas -->
        <v-expansion-panels v-model="activePanel" class="expandAreas">
          <v-expansion-panel>
            <v-expansion-panel-title>
              <template #default="{}">
                Other areas
                {{
                  areasOfInterest.others?.length
                    ? ` (${areasOfInterest.others.length})`
                    : ""
                }}</template
              >
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <!-- Loading  -->
              <v-container v-if="loadingAreasOfInterest" class="pa-0">
                <v-skeleton-loader
                  v-for="n in 3"
                  :key="n"
                  class="mx-auto border"
                  width="100%"
                  type="list-item-three-line"
                ></v-skeleton-loader>
              </v-container>
              <v-card
                v-else
                v-for="area in areasOfInterest.others"
                flat
                :key="area.id"
                class="pa-0"
                min-height="80px"
                style="
                  border-top: solid gray 1px;
                  border-bottom: solid gray 1px;
                "
              >
                <v-card-actions>
                  <v-card-actions class="pa-0 mr-1" style="min-width: 15%">
                    <v-checkbox color="primary"></v-checkbox>
                  </v-card-actions>
                  <v-card-actions
                    class="px-0 py-2 text-left"
                    style="width: 75%"
                  >
                    <div style="width: 80%">
                      {{ area.area_display_name || area.area_name }}
                      <div class="d-flex flex-row mt-2" style="width: 100%">
                        <div class="flex-row" style="width: 50%">
                          <v-icon :color="area.cmn_value_colour" class="mr-2">
                            mdi-square
                          </v-icon>
                          <b>CNI: </b> {{ area.cmn_value }}
                        </div>
                        <div class="flex-row" style="width: 50%">
                          <v-icon :color="area.imd_value_colour" class="mr-2">
                            mdi-square
                          </v-icon>
                          <b>IoD: </b> {{ area.imd_value }}
                        </div>
                      </div>
                    </div>
                  </v-card-actions>
                  <v-spacer />
                  <v-card-actions class="pa-0 mr-3">
                    <v-icon icon="mdi-magnify-plus-outline"></v-icon>
                  </v-card-actions>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-divider />
    </v-card>
    <v-divider />

    <!-- View summary report -->
    <v-card class="pa-4 text-center">
      <div
        class="truncateLogo"
        id="truncateLogo"
        :title="
          isEllipsisActive
            ? 'Select at least one area to access the report'
            : ''
        "
      >
        Select at least one area to access the report
      </div>
      <v-btn variant="elevated" color="success" width="80%" class="mt-2"
        >view summary report
      </v-btn>
    </v-card>
  </v-card>
</template>

<script>
import { useDisplay } from "vuetify";
import DiscoveryToolSelectIndicator from "@/components/DiscoveryToolSelectIndicator.vue";
import { formatDataValue } from "@/mixins/FormatDataValue";
import MapQuintiles from "@/components/MapQuintiles.vue";
import MapDataLevelSelect from "@/components/MapDataLevelSelect.vue";

export default {
  name: "DiscoveryToolMapSidebar",
  components: {
    MapQuintiles,
    DiscoveryToolSelectIndicator,
    MapDataLevelSelect,
  },
  data: () => ({
    height: useDisplay().height,
    activePanel: [0],
    indicatorDialog: false,
    lockView: false,
    areaName: null,
    value: null,
    loadingAreasOfInterest: false,
    areasOfInterest: {
      disadvantaged: [],
      others: [],
    },
  }),
  emits: ["closeMap", "changeIndicator"],
  props: {
    viewInfo: {
      type: Object,
      default: () => ({}),
    },
    areaMouseOverInfo: {
      type: Object,
      default: () => ({}),
    },
    colourScheme: {
      type: Array,
      default: () => [],
    },
    lockedView: {
      type: Boolean,
      default: false,
    },
    indicators: {
      type: Array,
      default: () => [],
    },
    loadingIndicators: {
      type: Boolean,
      default: false,
    },
    selectedIndicatorProp: {
      type: Object,
      default: () => ({}),
    },
    selectedLAorCLA: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.getAreasOfInterest();
  },
  computed: {
    isEllipsisActive: {
      get() {
        let element = document.getElementById("truncateLogo");

        if (element) {
          return element.offsetWidth < element.scrollWidth;
        }

        return false;
      },
    },
    levelNameAbr() {
      if (!this.viewInfo.viewportInfo.dataLevel) {
        return "Unknown";
      } else {
        const level = this.$store.state.config.siteConfig.area_level_names.find(
          (item) => item.area_level === this.viewInfo.viewportInfo.dataLevel,
        );
        return level ? level.area_level_name_abr : "Unknown";
      }
    },
    selectedIndicator: {
      get() {
        return this.selectedIndicatorProp;
      },
      set(val) {
        this.$emit("update:selectedIndicatorProp", val);
      },
    },
  },
  methods: {
    getAreaValue(value) {
      if (this.viewInfo.indicatorInfo) {
        // return something nicer if there's nothing there
        if (value === null || value === undefined) {
          return "None";
        }

        // round it to the decimal places
        value = formatDataValue(
          value,
          this.viewInfo.indicatorInfo.decimal_places,
        );

        if (this.viewInfo.indicatorInfo.suffix === "%" && value > 100) {
          // catch anything over 100%
          value = 100;
        }

        let prefix = this.viewInfo.indicatorInfo.prefix
          ? this.viewInfo.indicatorInfo.prefix
          : "";
        let suffix = this.viewInfo.indicatorInfo.suffix
          ? this.viewInfo.indicatorInfo.suffix
          : "";

        // send it back with any prefix or suffix
        return prefix + value + suffix;
      } else {
        return "-";
      }
    },
    getAreasOfInterest() {
      this.loadingAreasOfInterest = true;
      this.$axios
        .post("/get-areas-of-interest-discovery-tool", {
          area_ids: this.selectedLAorCLA,
        })
        .then((response) => {
          // handle success
          this.areasOfInterest.others = response.data.others;

          // pass it to the map
          this.$emit("areasOfInterestChanged", this.areasOfInterest);
          this.loadingAreasOfInterest = false;
        })
        .catch((error) => {
          // handle error
          this.loadingAreasOfInterest = false;
          console.error(error);
        });
    },
    getLegendData(entry) {
      if (this.viewInfo.indicatorInfo) {
        let min = entry + "_min";
        let max = entry + "_max";
        let pre = this.viewInfo.indicatorInfo.prefix
          ? this.viewInfo.indicatorInfo.prefix
          : "";
        let suf = this.viewInfo.indicatorInfo.suffix
          ? this.viewInfo.indicatorInfo.suffix
          : "";

        if (
          this.viewInfo.quintiles[min] !== null &&
          this.viewInfo.quintiles[max] !== null
        ) {
          // catch anything over 100%
          if (
            this.viewInfo.indicatorInfo.suffix === "%" &&
            this.viewInfo.quintiles[max] > 100
          ) {
            // eslint-disable-next-line vue/no-mutating-props
            this.viewInfo.quintiles[max] = 100;
          }

          return (
            "" +
            pre +
            formatDataValue(
              this.viewInfo.quintiles[min],
              this.viewInfo.indicatorInfo.decimal_places,
            ) +
            suf +
            " - " +
            pre +
            formatDataValue(
              this.viewInfo.quintiles[max],
              this.viewInfo.indicatorInfo.decimal_places,
            ) +
            suf
          );
        }
        return "-";
      } else {
        return "-";
      }
    },
  },
  watch: {
    areaMouseOverInfo: {
      handler() {
        if (this.areaMouseOverInfo.areaInfo) {
          if (!this.areaMouseOverInfo.freeze) {
            this.areaName =
              this.areaMouseOverInfo.areaInfo.feature.getProperty("area_name");
            this.value =
              this.areaMouseOverInfo.areaInfo.feature.getProperty("value");
          }
        } else {
          this.areaName = null;
          this.value = null;
        }
      },
      deep: true,
    },
    selectedIndicator(val, oldVal) {
      if (val !== oldVal && !this.loadingIndicators) {
        this.$emit("changeIndicator", val);
      }
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: 20px;
  font-weight: 520;
  padding: 10px;
}

.truncateLogo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
/* remove extra for some fields  */
.displayDataSwitcher .v-input__details,
.levelDropdown .v-input__details {
  display: none;
}

/* make the space between the rows smaller */
.loadingQuintiles .v-skeleton-loader__text {
  margin: 5px;
}

.expandAreas .v-expansion-panel-text__wrapper {
  padding: 0;
}
</style>
