<template>
  <div class="text-center">
    <v-dialog
      v-model="computedAddChartDialog"
      width="500"
      scrollable
      persistent
    >
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-left"
        >
          <v-toolbar-title v-if="!changeChart">Add Chart</v-toolbar-title>
          <v-toolbar-title v-else>Change Chart</v-toolbar-title>
        </v-toolbar>
        <v-card-actions height="70vh">
          <v-select
            v-model="selectedChartType"
            :items="availableChartTypes"
            label="Select Chart"
            variant="outlined"
            density="compact"
            rounded="0"
            class="select"
            align-center
            @update:modelValue="chartSelected"
          >
          </v-select>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            rounded="0"
            variant="elevated"
            @click="computedAddChartDialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import FormsInputsAndControls from '@/components/AddMetaTable'

export default {
  name: "ADDMETATABLE",
  data: () => ({
    tableData: [],
    selectedChartType: null,
    availableChartTypes: ["Bar", "Stacked Bar", "Line"],
  }),
  computed: {
    computedAddChartDialog: {
      get() {
        return this.addChartDialog;
      },
      set(val) {
        this.$emit("update:addChartDialog", val);
      },
    },
    reportThemeObject: {
      get() {
        return this.$store.state.reportThemeObject;
      },
      set(value) {
        this.$store.commit("setReportThemeObject", value);
      },
    },
  },
  components: {
    // FormsInputsAndControls
  },
  props: {
    addChartDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    changeChart: {
      type: Boolean,
      required: false,
      default: false,
    },
    configID: null,
  },
  mounted() {
    this.selectedChartType = null;
  },
  methods: {
    chartSelected() {
      if (!this.changeChart) {
        this.reportThemeObject["newChart"] = this.selectedChartType;
      } else {
        for (var i = 0; i < this.reportThemeObject.config.length; i++) {
          if (this.reportThemeObject.config[i].id === this.configID) {
            // console.log(this.configID);
            this.reportThemeObject.config[i].chartType = this.selectedChartType;
            break;
          }
        }
      }
      this.$emit("updateTheme");
      this.$nextTick(() => {
        this.selectedChartType = null;
        this.computedAddChartDialog = false;
      });
    },
  },
  watch: {
    computedAddChartDialog(val) {
      if (!val) {
        this.$emit("cancelEdit");
      }
    },
  },
};
</script>

<style scoped></style>
