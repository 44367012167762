<template>
  <div>
    <v-dialog
      v-model="computedStandardCustomAreaDialog"
      scrollable
      persistent
      max-width="1500px"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          tile
          variant="elevated"
          v-bind="props"
          id="standardAreaButton"
          class="mx-4"
          @click="buildCustomArea()"
          aria-label="Standard Area"
        >
          Standard Area
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5"
          max-height="64px"
        >
          <v-btn
            id="closeStandardAreaDialogButton"
            icon="mdi-close"
            dark
            @click="closeDialog"
            aria-label="Close"
          />
          <v-toolbar-title v-if="customAreasIndex != null"
            >Edit Custom Area
          </v-toolbar-title>
          <v-toolbar-title v-else>Create Custom Area</v-toolbar-title>
        </v-toolbar>
        <v-card-text
          v-if="loadingAreas && editMode"
          style="height: 80vh; overflow: hidden"
        >
          <v-card-actions tile elevation="0" class="progress">
            <div class="progressText">
              <v-progress-circular
                :size="200"
                :width="3"
                color="#51627C"
                indeterminate
                >Loading Areas
              </v-progress-circular>
            </div>
          </v-card-actions>
        </v-card-text>
        <v-card-text v-if="!loadingAreas" style="height: 80vh" tile>
          <!-- Core fields -->
          <v-card-subtitle class="mt-2">Basic information</v-card-subtitle>
          <v-card-actions>
            <v-form ref="form" v-model="validation" style="width: 100%">
              <!--    Edit mode    -->
              <v-row class="mt-0" v-if="customAreasIndex != null">
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  style="padding-top: 0px !important"
                >
                  <Field
                    :schema="fieldSchema.name"
                    v-model:value="customAreas[customAreasIndex].name"
                    id="editing-area-name-field"
                    :name="customAreas[customAreasIndex].name"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  style="padding-top: 0px !important"
                >
                  <Field
                    :schema="fieldSchema.custom_area_category_id"
                    v-model:value="
                      customAreas[customAreasIndex].custom_area_category_id
                    "
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  style="padding-top: 0px !important"
                >
                  <Field
                    :schema="fieldSchema.description"
                    v-model:value="customAreas[customAreasIndex].description"
                  />
                </v-col>
              </v-row>
              <!--    Create mode    -->
              <v-row v-else class="mt-0" style="width: 100%">
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  style="padding-top: 0px !important"
                >
                  <Field
                    id="creation-area-name-field"
                    :schema="fieldSchema.name"
                    v-model:value="customArea.name"
                    @click="resetApiValidationErrors('name')"
                  />
                  <div
                    v-if="customAreaNameLength >= 80"
                    :class="customAreaNameLength > 100 ? 'text-red' : ''"
                    style="position: relative; top: -20px; left: 280px"
                  >
                    {{ customAreaNameLength }}/100
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  style="padding-top: 0px !important"
                >
                  <Field
                    id="creation-area-category-field"
                    :schema="fieldSchema.custom_area_category_id"
                    v-model:value="customArea.custom_area_category_id"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  style="padding-top: 0px !important"
                >
                  <Field
                    :schema="fieldSchema.description"
                    v-model:value="customArea.description"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-actions>
          <!-- Select standard areas section -->
          <!-- <v-card-title>Create by standard area</v-card-title> -->
          <v-card-actions>
            <v-row>
              <!-- Available areas -->
              <v-col cols="6">
                <v-card tile pa-5 v-if="areaLevels">
                  <v-card-title class="mb-2">Available Areas</v-card-title>
                  <v-card-actions class="areaLevelFilters">
                    <v-select
                      prepend-inner-icon="mdi-filter"
                      v-model="selectedAreaLevel"
                      :items="areaLevels"
                      item-value="area_level"
                      item-title="area_level_name"
                      label="Filter by area level"
                      :loading="loadingTimepoints"
                      id="filter-by-area-level"
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      :disabled="timepoints.length === 0"
                      class="searchFields pb-0 mb-0"
                      @update:modelValue="getParentAreas"
                      style="max-width: 75%"
                    >
                    </v-select>
                    <v-select
                      prepend-inner-icon="mdi-calendar"
                      v-model="selectedTimepoint"
                      :items="levelTimepoints"
                      label="Year"
                      :disabled="
                        !selectedAreaLevel && customAreasIndex === null
                      "
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      title="By default the latest"
                      class="ml-3 pb-0 mb-0"
                      style="max-width: 25%"
                    >
                    </v-select>
                  </v-card-actions>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6" v-if="showSearchField">
                        <div>
                          <v-text-field
                            :single-line="true"
                            variant="outlined"
                            density="compact"
                            rounded="0"
                            clearable
                            hide-details="true"
                            class="fields pb-0 mb-0"
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            label="Search"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <!-- Show secondary filter if first one is LSOA/MSOA/Ward -->
                      <v-col cols="6" v-if="needFilteringByLA">
                        <div>
                          <v-select
                            prepend-inner-icon="mdi-filter"
                            v-model="selectedParentAreaID"
                            :items="parentAreas"
                            :loading="!parentAreasLoaded"
                            item-title="area_name"
                            item-value="id"
                            :label="level6Name"
                            variant="outlined"
                            density="compact"
                            rounded="0"
                            class="searchFields pb-0 mb-0"
                            hide-details="true"
                            @update:modelValue="getAreasByAreaLevelAndParent"
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                  <!-- Show areas only if all necessary filters have been filled -->
                  <v-card-actions
                    v-if="
                      (selectedAreaLevel && !needFilteringByLA) ||
                      selectedParentAreaID
                    "
                  >
                    <v-data-table
                      fixed-header
                      :headers="availableAreasTableHeaders"
                      :items="areasByAreaLevel"
                      :loading="!areasByAreaLevelLoaded"
                      :items-per-page="50"
                      class="elevation-0"
                      style="width: 100%"
                      :search="search"
                      height="25.5vh"
                    >
                      <!--  Bulk creation for wards  -->
                      <template
                        v-slot:[`header.bulk_ward_creation`]="{ column }"
                      >
                        <v-btn
                          v-if="selectedAreaLevel === 5"
                          @click="bulkWardsPackage"
                          :disabled="!areasByAreaLevelLoaded"
                          tile
                          width="110px"
                          variant="elevated"
                          color="primary"
                          aria-label="Set up all"
                        >
                          <v-tooltip activator="parent" location="bottom"
                            >Set up all wards in this list
                          </v-tooltip>
                          {{ column.title }}
                        </v-btn>
                      </template>

                      <template v-slot:item="{ item }">
                        <tr
                          @click="toggleCheckbox(item)"
                          id="area"
                          class="areaRow"
                        >
                          <td>
                            {{ item.area_display_name }}
                          </td>
                          <td>
                            <!--  just to put some space  -->
                          </td>
                          <td style="padding-left: 9px">
                            <v-checkbox
                              v-model="item.add"
                              v-if="renderCheckbox"
                              hide-details
                              color="primary"
                            />
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-actions>
                </v-card>
                <v-card-subtitle
                  v-if="this.$store.state.displayOnsSource"
                  class="source"
                >
                  <p>
                    Source: Office for National Statistics licensed under the
                    Open Government Licence v.3.0
                  </p>
                  <p>
                    Contains OS data © Crown copyright and database right
                    {{ year }}
                  </p>
                </v-card-subtitle>
              </v-col>
              <!-- Selected areas -->
              <v-col cols="6">
                <v-card tile pa-5>
                  <!-- <v-card-title>Selected Areas</v-card-title> -->
                  <template v-if="customAreasIndex != null">
                    <v-card-actions
                      v-if="customAreas[customAreasIndex].areas.length > 0"
                    >
                      <v-data-table
                        fixed-header
                        :headers="selectedAreasTableHeaders"
                        :items="customAreas[customAreasIndex].areas"
                        :items-per-page="50"
                        class="elevation-0"
                        style="width: 100%"
                        height="45vh"
                      >
                        <template v-slot:[`item.remove`]="{ item }">
                          <v-icon
                            aria-label="delete"
                            class="deleteAreaIcon"
                            small
                            @click="removeArea(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-card-actions>
                  </template>
                  <template v-else>
                    <v-card-actions v-if="customArea.areas.length > 0">
                      <v-data-table
                        fixed-header
                        :headers="selectedAreasTableHeaders"
                        :items="customArea.areas"
                        :items-per-page="-1"
                        class="elevation-0"
                        style="width: 100%"
                        height="45vh"
                      >
                        <template v-slot:[`item.remove`]="{ item }">
                          <v-icon
                            aria-label="delete"
                            class="deleteAreaIcon"
                            small
                            @click="removeArea(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-card-actions>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-card-actions>
          <!-- Buttons -->
        </v-card-text>
        <v-card v-if="!loadingAreas" tile>
          <v-card-actions v-if="fieldSchema">
            <v-spacer />
            <v-btn
              :disabled="disabledSaveButton"
              color="success"
              id="saveButton"
              variant="elevated"
              tile
              @click="upsertCustomArea()"
              width="100px"
              aria-label="save"
            >
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bulkWardSetUpDialog"
      scrollable
      persistent
      max-width="700px"
    >
      <v-card min-height="70vh">
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>Bulk wards set up</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-card style="height: 70vh" tile>
          <v-card-text>
            <v-data-table
              v-if="!duplicateNames.length"
              fixed-header
              :headers="wardsToBulkUploadHeaders"
              :items="filteredWards"
              :items-per-page="-1"
              class="elevation-0"
              style="width: 100%"
              height="58vh"
            >
              <template v-slot:[`header.custom_area_category_id`]>
                <v-select
                  v-model="customArea.custom_area_category_id"
                  :items="fieldSchema.custom_area_category_id.selectItems"
                  item-title="name"
                  item-value="id"
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  label="Select for all"
                  @update:modelValue="setCategoryForAll($event)"
                  class="mt-3"
                  return-object
                ></v-select>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    <v-select
                      v-model="item.custom_area_category_id"
                      :items="fieldSchema.custom_area_category_id.selectItems"
                      item-title="name"
                      item-value="id"
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      label="Category"
                      class="wardCategory mt-3 mb-3"
                    ></v-select>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <!--  Duplicates  -->
            <v-data-table
              v-else
              fixed-header
              :headers="[
                {
                  text: 'Custom Area',
                  align: 'start',
                  value: 'name',
                  width: '70%',
                },
                {
                  text: 'Category',
                  value: 'custom_area_category_id',
                  width: '30%',
                },
              ]"
              :items="duplicateNames"
              :items-per-page="-1"
              class="elevation-0 text-red"
              hide-default-footer
              style="width: 100%"
              height="58vh"
            >
              <template v-slot:[`item.custom_area_category_id`]="{ item }">
                {{
                  item.custom_area_category_id
                    ? categories.find(
                        (category) =>
                          category.id === item.custom_area_category_id,
                      ).name
                    : "Uncategorized"
                }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card tile>
          <v-card-actions>
            <v-btn
              @click="
                bulkWardSetUpDialog = false;
                filteredWards = [];
              "
              color="error"
              variant="elevated"
              tile
              aria-label="close"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="!duplicateNames.length"
              @click="bulkWardCreation"
              color="success"
              variant="elevated"
              tile
              aria-label="create"
            >
              create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Field from "@/components/Fields.vue";

export default {
  name: "CustomAreaManagerUpsert",
  data: () => ({
    loadingAreaLevels: true,
    loadingTimepoints: true,
    fieldSchema: {},
    areasByAreaLevelLoaded: false,
    parentAreasLoaded: false,
    customArea: {
      areas: [],
      type: null,
      type_id: null,
    },
    customAreasClone: [],
    validation: false,
    item: false,
    areaLevels: [],
    selectedAreaLevel: null,
    areasByAreaLevel: [],
    renderCheckbox: false,
    selectedParentAreaID: null,
    parentAreas: [],
    availableAreasTableHeaders: [
      {
        title: "Area",
        align: "start",
        value: "area_display_name",
        width: "70%",
      },
      {
        title: "Set up all",
        value: "bulk_ward_creation",
        sortable: false,
      },
      {
        title: "Add",
        value: "add",
        sortable: false,
        width: "20%",
      },
    ],
    wardsToBulkUploadHeaders: [
      {
        title: "Custom Area",
        align: "start",
        value: "area_display_name",
        width: "70%",
        sortable: false,
      },
      {
        title: "Category",
        value: "custom_area_category_id",
        width: "30%",
        sortable: false,
      },
    ],
    selectedAreasTableHeaders: [
      {
        title: "Area",
        align: "start",
        value: "area_display_name",
        width: "80%",
      },
      {
        title: "Remove",
        value: "remove",
        sortable: false,
        width: "20%",
      },
    ],
    search: "",
    loadingAreas: false,
    bulkWardSetUpDialog: false,
    filteredWards: [],
    duplicateNames: [],
    timepoints: [],
    selectedTimepoint: null,
    levelTimepoints: [],
  }),
  components: {
    Field,
  },
  computed: {
    showSearchField: {
      get() {
        if (
          (this.$store.getters.siteConfig.site_country === "aus" ||
            this.selectedAreaLevel === 6 ||
            this.selectedAreaLevel === 14 ||
            this.selectedAreaLevel === 6) &&
          this.areasByAreaLevelLoaded
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
    level6Name() {
      let country = this.$store.getters.siteConfig.site_country;

      if (country == "aus") {
        return "Filter";
      } else {
        return "Filter by Local Authority";
      }
    },
    year() {
      return new Date().getFullYear();
    },
    computedStandardCustomAreaDialog: {
      get() {
        return this.standardCustomAreaDialog;
      },
      set(val) {
        this.$emit("update:standardCustomAreaDialog", val);
      },
    },
    customAreasIndex: {
      get() {
        return this.areaIndex;
      },
      set(val) {
        this.$emit("update:areaIndex", val);
      },
    },
    needFilteringByLA() {
      if (this.$store.state.config.siteConfig.site_country === "aus") {
        return false;
      } else {
        // area level ids that we need to lister by parent: LSOA, MSOA, WD
        const needFiltering = [1, 2, 5];

        return needFiltering.includes(this.selectedAreaLevel);
      }
    },
    customAreas: {
      get() {
        return this.$store.state.customAreas;
      },
      set(value) {
        this.$store.commit("setCustomAreas", value);
      },
    },
    disabledSaveButton: {
      get() {
        if (this.customAreasIndex !== null) {
          // edit
          return (
            !this.customAreas[this.customAreasIndex].areas.length ||
            (this.customAreas[this.customAreasIndex].description
              ? this.customAreas[this.customAreasIndex].description.length >=
                250
              : false)
          );
        } else {
          // create
          return (
            !this.customArea.areas.length ||
            (this.customArea.description
              ? this.customArea.description.length > 250
              : false)
          );
        }
      },
    },
    editMode() {
      return this.customAreasIndex !== null;
    },
    customAreaNameLength() {
      return this.customArea.name ? this.customArea.name.length : 0;
    },
  },
  props: {
    standardCustomAreaDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    areaIndex: {},
    schema: {},
    categories: Array,
  },
  mounted() {
    this.getTimepoints();
    this.getAreaLevels();
    this.getTypeId();
  },
  methods: {
    getTypeId() {
      this.$axios
        .get("/custom-areas-types/search/standard-area")
        .then(
          function (response) {
            // handle success
            this.customArea.type_id = response.data.id;
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemBusy", false);
          }.bind(this),
        );
    },
    getTimepoints() {
      this.loadingTimepoints = true;
      this.$axios
        .get("/get-timepoints-standard-area")
        .then(
          function (response) {
            // handle success
            this.timepoints = response.data;
            this.loadingTimepoints = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed",
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    // Clear dialog and close it
    closeDialog() {
      // Reset page to initial state
      this.computedStandardCustomAreaDialog = false;
      this.areasByAreaLevel = [];
      this.selectedAreaLevel = null;
      this.selectedParentAreaID = null;
      this.customAreasIndex = null;
      this.loadingAreas = false;
      this.duplicateNames = [];
      this.levelTimepoints = [];
      this.selectedTimepoint = null;

      // Restore originals
      this.customAreas = this.customAreasClone;
      this.customArea = {
        areas: [],
      };
    },
    // Makes available areas' checkboxes up to date with selected areas' checkboxes
    refreshCheckboxes() {
      this.renderCheckbox = false;
      // Set available areas to false unless a match is found on selected areas
      for (var i = 0; i < this.areasByAreaLevel.length; i++) {
        this.areasByAreaLevel[i].add = false;
        if (this.customAreasIndex != null) {
          for (
            let j = 0;
            j < this.customAreas[this.customAreasIndex].areas.length;
            j++
          ) {
            if (
              this.areasByAreaLevel[i].id ==
              this.customAreas[this.customAreasIndex].areas[j].id
            ) {
              this.areasByAreaLevel[i].add = true;
              continue;
            }
          }
        } else {
          for (let j = 0; j < this.customArea.areas.length; j++) {
            if (this.areasByAreaLevel[i].id == this.customArea.areas[j].id) {
              this.areasByAreaLevel[i].add = true;
              continue;
            }
          }
        }
      }
      this.$nextTick(() => {
        this.renderCheckbox = true;
      });
    },
    resetSelectedWithNewTimepoint() {
      // remove all selected areas
      if (this.customAreasIndex != null) {
        this.customAreas[this.customAreasIndex].areas = [];
      } else {
        this.customArea.areas = [];
      }
      this.refreshCheckboxes();
    },
    removeArea(item) {
      if (this.customAreasIndex != null) {
        // Handle unchecking
        for (
          let i = 0;
          i < this.customAreas[this.customAreasIndex].areas.length;
          i++
        ) {
          if (item.id == this.customAreas[this.customAreasIndex].areas[i].id) {
            this.customAreas[this.customAreasIndex].areas.splice(i, 1);
            this.refreshCheckboxes();
            return;
          }
        }
        // Handle ticking the box
        this.customAreas[this.customAreasIndex].areas.push(item);
      } else {
        // Handle unchecking
        for (let i = 0; i < this.customArea.areas.length; i++) {
          if (item.id == this.customArea.areas[i].id) {
            this.customArea.areas.splice(i, 1);
            this.refreshCheckboxes();
            return;
          }
        }
        // Handle ticking the box
        this.customArea.areas.push(item);
      }
    },
    // If there is a selected custom area, call the api for updating, else call the api for creating
    upsertCustomArea() {
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          if (this.customAreasIndex != null) {
            // Custom area update
            this.emit.emit("systemMessage", {
              title: "Updating Custom Area",
              message: "Updating",
              timeout: 4000,
              colour: "warning",
            });

            // Add the ids of selected areas to the custom area object
            this.customAreas[this.customAreasIndex].area_ids = [];
            for (
              let i = 0;
              i < this.customAreas[this.customAreasIndex].areas.length;
              i++
            ) {
              this.customAreas[this.customAreasIndex].area_ids.push(
                this.customAreas[this.customAreasIndex].areas[i].id,
              );
            }

            // add the timepoint
            this.customAreas[this.customAreasIndex].timepoint =
              this.selectedTimepoint;

            // make the call!
            this.$axios
              .put(
                "/custom-areas/" + this.customAreas[this.customAreasIndex].id,
                this.customAreas[this.customAreasIndex],
              )
              .then(() => {
                this.emit.emit("systemMessage", {
                  title: "Custom Area Update Complete",
                  message: "Success!",
                  timeout: 4000,
                  colour: "green",
                });
                // Update with new data
                this.customAreasClone = this.$cloneDeep(this.customAreas);
                this.closeDialog();
                this.emit.emit("systemBusy", false);
              })
              .catch((error) => {
                this.emit.emit("systemBusy", false);
                this.emit.emit("systemMessage", {
                  message: error.response.data.message,
                  title: "Error! Custom Area Update Failed",
                  timeout: -1,
                  colour: "red",
                });
                this.apiValidationErrors(error.response.data.errors);
              });
          } else {
            // Custom area create
            this.emit.emit("systemMessage", {
              title: "Creating New Custom Area",
              message: "Creating",
              timeout: 4000,
              colour: "warning",
            });
            this.emit.emit("systemBusy", true);

            // Add the ids of selected areas to the custom area object
            this.customArea.area_ids = [];
            for (let i = 0; i < this.customArea.areas.length; i++) {
              this.customArea.area_ids.push(this.customArea.areas[i].id);
            }

            // add the timepoint
            this.customArea.timepoint = this.selectedTimepoint;

            // make the call!
            this.$axios
              .post("/custom-areas", this.customArea)
              .then(() => {
                this.emit.emit("systemMessage", {
                  title: "Custom Area Creation Complete",
                  message: "Success!",
                  timeout: 3000,
                  colour: "success",
                });
                this.customAreasClone = this.$cloneDeep(this.customAreas);
                // Refresh custom areas table
                this.$emit("getCustomAreasByCategory");

                this.closeDialog();
                this.emit.emit("systemBusy", false);
              })
              .catch((error) => {
                this.emit.emit("systemBusy", false);
                this.emit.emit("systemMessage", {
                  message: error.response.data.message,
                  title: "Error! Custom Area Creation Failed",
                  timeout: -1,
                  colour: "error",
                });
                this.apiValidationErrors(error.response.data.errors);
              });
          }
        }
      });
    },
    getAreaLevels() {
      this.loadingAreaLevels = true;
      this.$axios
        .get("/list-area-data-levels")
        .then(
          function (response) {
            // handle success
            this.areaLevels = response.data;
            this.loadingAreaLevels = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all data levels",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getParentAreas() {
      // show available timepoints
      this.levelTimepoints = this.timepoints.filter(
        (item) => item.area_level === this.selectedAreaLevel,
      )[0].years;

      // set the latest timepoint available
      if (this.customAreasIndex === null && this.selectedTimepoint === null) {
        this.selectedTimepoint = Math.max(...this.levelTimepoints);
      }

      if (!this.levelTimepoints.includes(this.selectedTimepoint)) {
        this.resetSelectedWithNewTimepoint();
        this.selectedTimepoint = Math.max(...this.levelTimepoints);
      }

      // If LSOA, MSOA or WD, populate the secondary filter, else go straight to loading the areas
      if (this.needFilteringByLA) {
        // Clear secondary filter in case the user switches between LSOA/MSOA/WD
        this.selectedParentAreaID = null;
        // Clear areas while we wait for the user to pick from secondary filter
        this.areasByAreaLevel = [];

        // Get all LA level areas if we haven't already
        if (!this.parentAreas.length) {
          this.getFilterLA();
        }
      } else {
        this.getAreasByAreaLevel();
      }

      // Clear search box
      this.search = "";
    },
    getFilterLA() {
      this.parentAreasLoaded = false;
      this.parentAreas = [];

      this.$axios
        .get("/list-areas-by-level-int/6/" + this.selectedTimepoint)
        .then(
          function (response) {
            // handle success
            this.parentAreas = response.data;
            this.parentAreasLoaded = true;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get parent areas",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getAreasByAreaLevel() {
      this.areasByAreaLevelLoaded = false;
      this.areasByAreaLevel = [];
      this.search = null;
      this.$axios
        .get(
          "/list-areas-by-level-int/" +
            this.selectedAreaLevel +
            "/" +
            this.selectedTimepoint,
        )
        .then(
          function (response) {
            // handle success
            this.areasByAreaLevel = response.data;
            this.refreshCheckboxes();
            this.areasByAreaLevelLoaded = true;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get areas by area level",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    toggleCheckbox(item) {
      if (!this.areasByAreaLevelLoaded) return;

      if (this.customAreasIndex != null) {
        const areaIndex = this.customAreas[
          this.customAreasIndex
        ].areas.findIndex((area) => area.id === item.id);

        if (areaIndex !== -1) {
          this.customAreas[this.customAreasIndex].areas.splice(areaIndex, 1);
        } else {
          this.customAreas[this.customAreasIndex].areas.push(item);
        }

        this.refreshCheckboxes();
      } else {
        const areaIndex = this.customArea.areas.findIndex(
          (area) => area.id === item.id,
        );

        if (areaIndex !== -1) {
          this.customArea.areas.splice(areaIndex, 1);
        } else {
          this.customArea.areas.push(item);
        }

        this.refreshCheckboxes();
      }
    },
    // Get areas filtered by the primary and secondary filters at the same time
    getAreasByAreaLevelAndParent() {
      if (!this.selectedParentAreaID) return;

      this.areasByAreaLevelLoaded = false;
      this.$axios
        .get(
          "/areas-by-level-and-parent/" +
            this.selectedTimepoint +
            "/" +
            this.selectedAreaLevel +
            "/" +
            this.selectedParentAreaID,
        )
        .then(
          function (response) {
            // handle success
            this.areasByAreaLevel = response.data;
            this.refreshCheckboxes();
            this.areasByAreaLevelLoaded = true;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get areas by area level and parent",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    // Build empty fields for creation
    buildCustomArea() {
      this.getTypeId();
      for (const key in this.fieldSchema) {
        if (this.fieldSchema[key].useInCreationMode) {
          this.customArea[key] = null;
        }
      }
    },
    apiValidationErrors(errors) {
      for (let field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (let field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    getCustomAreasStandard() {
      this.loadingAreas = true;
      this.$axios
        .get(
          "/custom-areas-standard/" +
            this.customAreas[this.customAreasIndex].id,
        )
        .then(
          function (response) {
            // handle success
            this.customAreas[this.customAreasIndex].areas =
              response.data[0].areas;

            // add timepoint
            this.levelTimepoints.push(response.data[0].timepoint);
            this.selectedTimepoint = this.levelTimepoints[0];

            // add key 'add' to each object and set it to true
            this.customAreas[this.customAreasIndex].areas.forEach(
              function (area) {
                area.add = true;
              }.bind(this),
            );

            this.refreshCheckboxes();

            this.loadingAreas = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get areas",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    setCategoryForAll(category) {
      if (category === null) return;
      this.filteredWards.forEach((item) => {
        item.custom_area_category_id =
          typeof category === "object" ? category.id : category;
      });
    },
    bulkWardsPackage() {
      this.duplicateNames = [];
      this.bulkWardSetUpDialog = true;

      for (let i = 0; i < this.areasByAreaLevel.length; i++) {
        // build custom area for creation
        this.filteredWards[i] = {
          name: this.areasByAreaLevel[i].area_display_name,
          custom_area_category_id: null,
          area_ids: [this.areasByAreaLevel[i].id],
          type_id: 1,
        };
      }

      this.setCategoryForAll(this.customArea.custom_area_category_id);
    },
    bulkWardCreation() {
      this.emit.emit("systemMessage", {
        title: "This may take a while...",
        message: "Creating custom areas",
        timeout: -1,
        colour: "warning",
      });

      this.$axios
        .post("/bulk-custom-area-ward-creation", this.filteredWards)
        .then(
          function () {
            // handle success
            this.emit.emit("systemMessage", {
              title: "Success",
              message: "Areas Created Successfully!",
              timeout: 2000,
              colour: "success",
            });
            this.bulkWardSetUpDialog = false;
            this.closeDialog();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);

            if (error.response.status === 422) {
              // duplicates found
              this.emit.emit("systemMessage", {
                title: "Message",
                message:
                  "In the list below you can see the custom areas that already exist. Delete or rename them manually and try again.",
                timeout: 2000,
                colour: "info",
              });
              this.duplicateNames = error.response.data.duplicates;
            } else {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Bulk creation failed",
                timeout: -1,
                colour: "red",
              });
            }
          }.bind(this),
        );
    },
  },
  watch: {
    standardCustomAreaDialog(val) {
      if (val) {
        this.fieldSchema = this.$cloneDeep(this.schema);
        this.resetApiValidationErrors();
        // Backup custom areas
        this.customAreasClone = this.$cloneDeep(this.customAreas);

        // if we are in edit mode, get the standard custom areas
        if (this.editMode) {
          this.getCustomAreasStandard();
        }
      }
    },
    categories: {
      handler(val) {
        if (val && Object.keys(this.fieldSchema).length > 0) {
          this.fieldSchema.custom_area_category_id.selectItems = val;
        }
      },
      deep: true,
    },
    selectedTimepoint: {
      handler(value) {
        if (value && this.selectedAreaLevel) {
          // get the areas if different timepoint selected
          this.resetSelectedWithNewTimepoint();
          // If LSOA, MSOA or WD, populate the secondary filter, else go straight to loading the areas
          if (this.needFilteringByLA) {
            this.getFilterLA();
            // Get all LA level areas
            this.getAreasByAreaLevelAndParent();
          } else {
            this.getAreasByAreaLevel();
          }
        }
      },
    },
  },
};
</script>
<style scoped>
.areaRow:hover {
  cursor: pointer;
  background-color: #e0e0e0;
}

.wardCategory .v-text-field__details {
  display: none;
}

.deleteAreaIcon:hover {
  transform: scale(1.1);
  color: #e74c3c;
  transition:
    color 0.2s,
    transform 0.2s;
}

.source {
  font-size: 10px;
  position: "absolute";
  bottom: "0";
  margin-top: 5px;
}
</style>
