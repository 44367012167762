<template>
  <v-dialog v-model="accessibilityModeDialogue" max-width="600">
    <template v-slot:activator="{}">
      <v-btn
        id="accessibilityBtn"
        color="primary"
        variant="elevated"
        tile
        @click="accessibilityModeDialogue = true"
        :disabled="loadingData"
        aria-label="show icons"
      >
        Accessibility
      </v-btn>
    </template>
    <v-card tile>
      <v-card-title
        :style="
          'background-color: ' +
          this.$store.state.config.siteConfig.toolbar_colour
        "
        class="text-h6 text-center mb-3"
      >
        <v-spacer>Accessibility</v-spacer>
      </v-card-title>
      <v-card-text style="font-size: 15px" class="pb-0">
        For those with colour blindness or other visual impairments, the
        <b>Show icons on cells</b>
        toggle below enables arrows that indicate whether the figure shown is
        High, Medium or Low within the range of information shown on the
        Dashboard
        <v-card-actions>
          <v-switch
            id="showIconsOnDashboard"
            v-model="showIconsOnDashboard"
            label="Show icons on cells"
            @change="rememberChoice"
            aria-label="show icons on cells"
          ></v-switch>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-card tile>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="closeAccessibilityBtn"
          color="red"
          variant="elevated"
          class="white--text"
          tile
          aria-label="close"
          @click="accessibilityModeDialogue = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AccessibilityDialogueOnDashboard",
  data() {
    return {
      accessibilityModeDialogue: false,
    };
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showIconsOnDashboard: {
      get() {
        return this.$store.state.config.userProfile.show_icons_on_dashboard;
      },
      set(value) {
        this.$store.commit("setShowIconsOnDashboard", value);
      },
    },
  },
  methods: {
    rememberChoice() {
      this.$axios
        .post("/update-show-icons-on-dashboard", {
          show_icons_on_dashboard: this.showIconsOnDashboard,
        })
        .then(function () {}.bind(this))
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed",
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
  },
};
</script>
<style scoped>
#accessibilityBtn {
  padding: 0px 8px;
  margin: 5px;
}
</style>
