<template>
  <Bar
    v-if="loaded"
    :data="chartData"
    :options="chartOptions"
    :aria-label="'Bar chart showing ' + clonedChartConfig.title"
  />
</template>
<script>
import { Bar } from "vue-chartjs";
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  // ChartDataLabels,
);

export default {
  name: "BarChart",
  components: { Bar },
  data: () => ({
    prefix: null,
    suffix: null,
    loaded: false,
    options: null,
    clonedChartConfig: null,
  }),
  computed: {
    chartData() {
      return this.clonedChartConfig;
    },
    chartOptions() {
      return this.options;
    },
  },
  props: {
    chartConfig: null,
    stacked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.clonedChartConfig = JSON.parse(JSON.stringify(this.chartConfig));
    this.lineBreakLables();
    this.buildChart();
  },
  methods: {
    // this breaks up the chart labels onto multiple lines if they are too long
    lineBreakLables() {
      var labelThresHold = this.stacked ? 2 : 2;
      var maxWordsInARow =
        this.clonedChartConfig.labels.toString().length < 170 ? 3 : 1;
      // if there are more than the labels threshold
      if (
        this.clonedChartConfig.labels.length > labelThresHold ||
        this.clonedChartConfig.labels.toString().length > 140
      ) {
        for (var i = 0; i < this.clonedChartConfig.labels.length; i++) {
          if (this.clonedChartConfig.labels[i].length > 25) {
            let explode = this.clonedChartConfig.labels[i].split(" ");
            let labelArray = [];
            labelArray.push("");
            let counter = 0;
            for (var j = 0; j < explode.length; j++) {
              if (j && j % maxWordsInARow === 0) {
                counter++;
                labelArray.push("");
              }
              labelArray[counter] = labelArray[counter] + " " + explode[j];
            }
            this.clonedChartConfig.labels[i] = labelArray;
          }
        }
      }
    },
    sortPrefixesAndSuffixes() {
      // these charts only like numbers, so we have to stript out any Prefixes & Suffixes in the data and add concatinate them to the the labels and tooltips later on
      for (var i = 0; i < this.clonedChartConfig.datasets.length; i++) {
        // only do this the once
        if (
          typeof this.clonedChartConfig.datasets[i].prefixes === "undefined"
        ) {
          this.clonedChartConfig.datasets[i].prefixes = [];
          this.clonedChartConfig.datasets[i].suffixes = [];
          for (
            var j = 0;
            j < this.clonedChartConfig.datasets[i].data.length;
            j++
          ) {
            // If there is data, then process prefix and suffix
            if (this.clonedChartConfig.datasets[i].data[j]) {
              this.clonedChartConfig.datasets[i].data[j] = String(
                this.clonedChartConfig.datasets[i].data[j],
              );
              // Attempt to get the suffix
              var match =
                this.clonedChartConfig.datasets[i].data[j].match(/\D+$/);
              var newSuffix = match ? match[0] : "";
              this.suffix = newSuffix;

              // If the suffix has changed, report to user that there is mixed data
              if (this.suffix !== newSuffix) {
                this.$emit("data-error", this.clonedChartConfig.canvasID);
              }

              this.clonedChartConfig.datasets[i].suffixes.push(this.suffix);
              // Remove the suffix from the data
              this.clonedChartConfig.datasets[i].data[j] =
                this.clonedChartConfig.datasets[i].data[j].replace(/\D+$/, "");
              // Do similar to the above for the prefix
              this.clonedChartConfig.datasets[i].data[j] = String(
                this.clonedChartConfig.datasets[i].data[j],
              );
              match = this.clonedChartConfig.datasets[i].data[j].match(/^\D+/);
              this.prefix = match ? match[0] : "";
              this.clonedChartConfig.datasets[i].prefixes.push(this.prefix);
              // Remove the prefix from the data
              this.clonedChartConfig.datasets[i].data[j] =
                this.clonedChartConfig.datasets[i].data[j].replace(/^\D+/, "");
            } else {
              // No data so do behaviour for no prefix/suffix found
              this.clonedChartConfig.datasets[i].suffixes.push("");
              this.clonedChartConfig.datasets[i].prefixes.push("");
              // Alert the user
              this.$emit("data-error", this.clonedChartConfig.canvasID);
            }
          }
        }
      }
    },
    buildChart() {
      this.sortPrefixesAndSuffixes();
      // Sum the data for each stacked bar chart to find the largest value
      var largestValue = 0;
      var currentStack = 0;
      // Loop through each comparator
      for (var i = 0; i < this.clonedChartConfig.labels.length; i++) {
        // Loop through each indicator
        currentStack = 0;
        for (var j = 0; j < this.clonedChartConfig.datasets.length; j++) {
          var value = parseFloat(this.clonedChartConfig.datasets[j].data[i]);
          if (!isNaN(value)) {
            currentStack += value;
          }
        }
        if (currentStack > largestValue) {
          largestValue = currentStack;
        }
      }
      // For stacked bar charts, do not go over 100 on the Y axis if the data doesn't go over 100
      var ticks = {
        beginAtZero: true,
        callback: (value) => {
          return this.prefix + value + this.suffix;
        },
      };
      // Round down to nearest 1% to account for summing rounding errors.
      largestValue = Math.floor(largestValue);
      if (largestValue <= 100) {
        if (this.stacked) {
          ticks.max = 100;
        }
      }
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.clonedChartConfig.title,
            font: {
              size: 18,
              style: "normal",
              lineHeight: 1.2,
            },
          },
          // datalabels: { // for future development (Ben 29/04/2024)
          //   color: "white",
          //   display: function (context) {
          //     return context.dataset.data[context.dataIndex];
          //   },
          //   font: {
          //     weight: "bold",
          //   },
          //   // formatter: Math.round,
          // },
        },
        legend: {
          labels: {
            fontSize: 16,
          },
        },
        scales: {
          x: {
            stacked: this.stacked,
            display: true,
            ticks: {
              font: {
                size: 14,
                style: "normal",
                lineHeight: 1.2,
              },
            },
          },
          y: {
            stacked: this.stacked,
            display: true,
            title: {
              display: true,
              text: this.clonedChartConfig.chartYLabel,
              font: {
                size: 16,
                style: "normal",
                lineHeight: 1.2,
              },
            },
            padding: { top: 30, left: 0, right: 0, bottom: 0 },
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems) => {
              return (
                this.clonedChartConfig.datasets[tooltipItems.datasetIndex]
                  .prefixes[tooltipItems.index] +
                tooltipItems.yLabel +
                this.clonedChartConfig.datasets[tooltipItems.datasetIndex]
                  .suffixes[tooltipItems.index]
              );
            },
          },
        },
      };
      this.loaded = true;
    },
  },
  watch: {},
};
</script>

<style scoped></style>
