<template>
  <v-row>
    <v-col xs="6" md="8" xl="9" xxl="10" class="pr-0">
      <v-container class="pt-0 pl-0 pr-0 mr-1">
        <div>
          <div id="pano"></div>

          <div
            ref="mapCanvas"
            id="mapCanvas"
            class="map-canvas"
            :style="'height: ' + (height - 50) + 'px;'"
          ></div>
        </div>
      </v-container>
    </v-col>
    <v-col xs="6" md="4" xl="3" xxl="2" class="pl-0">
      <DiscoveryToolMapSidebar
        :viewInfo="viewInfo"
        :areaMouseOverInfo="areaMouseOverInfo"
        :colourScheme="colourScheme"
        :lockedView="lockedView"
        :indicators="indicators"
        :selectedLAorCLA="selectedLAorCLA"
        v-model:selectedIndicatorProp="selectedIndicator"
        :loadingIndicators="loadingIndicators"
        @areasOfInterestChanged="updateAreasOfInterest"
        @closeMap="closeDialog"
        @selectedDataLevelChanged="handleDataLevelSelected"
        @lockViewChanged="handleLockViewChanged"
        @changeIndicator="updateSelectedIndicator"
      />
    </v-col>
  </v-row>
</template>

<script>
/* global google */

// store map here so vue doesn't wrap it in a proxy - Google doesn't like that.
import { loadGoogleMaps } from "@/mixins/LoadGoogleMaps";
import { useDisplay } from "vuetify";
import { toRaw } from "vue";
import DiscoveryToolMapSidebar from "@/components/DiscoveryToolMapSidebar.vue";

let map = null;

export default {
  name: "DiscoveryToolMap",
  emits: ["displayMap"],
  components: { DiscoveryToolMapSidebar },
  data: () => ({
    height: useDisplay().height,
    center: { lat: 54.36, lng: -2.59 },
    zoom: 6,
    clientBoundary: [],
    polygonObjects: [],
    viewInfo: {
      viewportInfo: {},
      quintiles: { q1_min: "loading" },
      indicatorInfo: {
        id: null,
      },
      numeratorInfo: {},
    },
    indicators: [],
    loadingIndicators: true,
    selectedIndicator: {},
    zoomAreaLevels: {
      1: 6,
      2: 6,
      3: 6,
      4: 6,
      5: 6,
      6: 6,
      7: 6,
      8: 6, // LA
      9: 5,
      10: 5, //Ward
      11: 2, // MSOA
      12: 2,
      13: 1, // LSOA
      14: 1,
      15: 1,
      16: 1,
      17: 1,
      18: 1,
      19: 1,
      20: 1,
      21: 1,
      22: 1,
    },
    selectedDataLevel: null,
    lockedView: false,
    areaMouseOverInfo: {
      areaInfo: null,
      freeze: false,
    },
    colourScheme: [],
    currentDataLevel: null,
    latestQuintileLevel: 99,
    latestIndicatorId: 0,
    polygonsWithDataset: null,
    idleTimeout: null,
    hotspotFlag: "all",
    areasOfInterest: {
      disadvantaged: [],
      others: [],
    },
  }),
  mounted() {
    // load map
    loadGoogleMaps(this.center, this.zoom).then((loadedMap) => {
      map = loadedMap;

      this.mapSetup();
      this.getBoundaries();
    });

    //set data level to lsoa and lock view on page load
    this.selectedDataLevel = 1;
    this.setSelectedDataLevel();
    this.lockedView = true;
  },
  props: {
    selectedLAorCLA: {
      type: Array,
      required: true,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("displayMap", false);
    },
    updateAreasOfInterest(value) {
      this.areasOfInterest = value;
    },
    getIndicators() {
      this.loadingIndicators = true;
      this.$axios
        .get("/get-indicators-discovery-tool")
        .then((response) => {
          // handle success
          this.indicators = response.data;
          this.selectedIndicator = JSON.parse(
            JSON.stringify(this.indicators[0]),
          );
          this.viewInfo.indicatorInfo.id = this.selectedIndicator.id;
          this.viewInfo.viewportInfo.ind_id = this.selectedIndicator.id;
          this.loadingIndicators = false;
        })
        .catch((error) => {
          this.loadingIndicators = false;
          // handle error
          console.error(error);
        });
    },
    mapSetup() {
      this.zoomToDefaultView();

      // add listener to see if anything changes
      map.addListener(
        "idle",
        () => {
          this.deBounce();
        },
        { passive: true },
      );

      // set up all the view details
      this.viewInfo.viewportInfo = {
        dataLevel: this.viewInfo.viewportInfo.dataLevel
          ? this.viewInfo.viewportInfo.dataLevel
          : 6,
        xmin: 0,
        ymin: 0,
        xmax: 0,
        ymax: 0,
        ind_id: this.viewInfo.viewportInfo.ind_id || null,
      };

      // get the viewport to start
      this.deBounce();
    },
    deBounce() {
      // clear this
      clearTimeout(this.idleTimeout);
      // start the countdown again!
      this.idleTimeout = setTimeout(
        function () {
          this.updateViewDetails();
        }.bind(this),
        1250,
      );
    },
    updateViewDetails() {
      //PostGis docs - ST_MakeEnvelope(float xmin, float ymin, float xmax, float ymax, integer srid=unknown)
      // update view info
      if (map) {
        this.viewInfo.viewportInfo.xmin = map.getBounds().getSouthWest().lng();
        this.viewInfo.viewportInfo.ymin = map.getBounds().getSouthWest().lat();
        this.viewInfo.viewportInfo.xmax = map.getBounds().getNorthEast().lng();
        this.viewInfo.viewportInfo.ymax = map.getBounds().getNorthEast().lat();
      }

      if (this.selectedDataLevel) {
        //data level selected from dropdown
        this.setSelectedDataLevel();
      } else {
        //map zoom data level
        this.setZoomDataLevel();
      }

      // call this
      if (this.viewInfo.indicatorInfo.id) {
        this.getDataCount();
        this.getPolygonsWithDataset();
      }
    },
    setSelectedDataLevel() {
      //set data level to selected and reset flag
      if (!this.lockedView && this.selectedDataLevel) {
        this.viewInfo.viewportInfo.dataLevel = this.selectedDataLevel;
        this.selectedDataLevel = null;
      }
    },
    setZoomDataLevel() {
      if (
        //not locked & zoom is below data lowest level - set data level to lowest
        !this.lockedView &&
        this.viewInfo.indicatorInfo.lowest_show_level >
          this.zoomAreaLevels[map.getZoom()]
      ) {
        this.viewInfo.viewportInfo.dataLevel =
          this.viewInfo.indicatorInfo.lowest_show_level;
      } else {
        //not locked - set data level to zoom
        if (!this.lockedView) {
          this.viewInfo.viewportInfo.dataLevel =
            this.zoomAreaLevels[map.getZoom()];
        }
      }
    },
    updateSelectedIndicator(value) {
      this.selectedIndicator = value;
      this.viewInfo.viewportInfo.ind_id = value.id;
      if (this.viewInfo.viewportInfo.ind_id) {
        this.getIndicatorDetails();
      }
    },
    getIndicatorDetails() {
      this.emit.emit("systemBusy", true);
      this.currentDataLevel = null;
      this.viewInfo.quintiles = {
        q1_min: "loading",
      };
      this.$axios
        .get("/standard-metadata-map/" + this.selectedIndicator.id)
        .then(
          function (response) {
            // handle success
            this.viewInfo.indicatorInfo = response.data[0];
            this.viewInfo.numeratorInfo = response.data[1];
            this.colourScheme = response.data[0].colour_scheme;
            this.getDataCount();
            this.getPolygonsWithDataset();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemBusy", false);
          }.bind(this),
        );
    },
    getPolygonsWithDataset() {
      this.emit.emit("systemBusy", true);
      // add the the hotspot flag go determine what data to get back
      this.viewInfo.viewportInfo.hotspotFlag = this.hotspotFlag;

      // set the colours
      var colors = this.colourScheme;
      var color;

      this.$axios
        .post("/data-geometries", this.viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            this.polygonsWithDataset = response.data;

            let features = map.data;
            features.forEach(function (feature) {
              features.remove(feature);
            });
            if (this.polygonsWithDataset.features) {
              map.data.addGeoJson(this.polygonsWithDataset);
              this.addDataLayerListeners();
              map.data.setStyle(function (feature) {
                var quintile = feature.getProperty("quintile");
                switch (quintile) {
                  case 1:
                    color = "#" + colors[0];
                    break;
                  case 2:
                    color = "#" + colors[1];
                    break;
                  case 3:
                    color = "#" + colors[2];
                    break;
                  case 4:
                    color = "#" + colors[3];
                    break;
                  case 5:
                    color = "#" + colors[4];
                    break;
                  default:
                    color = "#" + colors[4];
                }
                return {
                  fillColor: color,
                  fillOpacity: 0.5,
                  strokeColor: "#FFFFFF",
                  strokeWeight: 0.5,
                };
              });
            }
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: 4000,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    addDataLayerListeners() {
      // add listener to see if anything changes
      // mouse over to show the area & value
      map.data.addListener(
        "mouseover",
        function (event) {
          if (!this.areaMouseOverInfo.freeze) {
            //update the data with the event, which includes the mouse overed feature
            this.areaMouseOverInfo.areaInfo = event;

            // take a note of the feature clicked
            this.mouseFeature = event.feature;

            // up the border as a highlight
            map.data.overrideStyle(this.mouseFeature, {
              strokeWeight: 3.5,
            });
          }
        }.bind(this),
      );

      map.data.addListener(
        "mouseout",
        function () {
          //update the data with the event, which includes the mouse overed feature
          if (!this.areaMouseOverInfo.freeze) {
            this.areaMouseOverInfo.areaInfo = null;

            // reset border
            map.data.overrideStyle(this.mouseFeature, {
              strokeWeight: 0.5,
            });
          }
        }.bind(this),
      );

      // click to freeze the selection
      map.data.addListener(
        "click",
        function (event) {
          // update the data with the event, which includes the mouse overed feature
          // work around for now to avoid propagation
          if (event.alreadyCalled_) {
            //catch second click propagated up to 'map' from the data_layer
          } else {
            event.alreadyCalled_ = true;
            this.areaMouseOverInfo.freeze = !this.areaMouseOverInfo.freeze;

            // if we've just set it false, remove the area highlight
            if (!this.areaMouseOverInfo.freeze) {
              map.data.overrideStyle(this.mouseFeature, {
                strokeWeight: 0.5,
              });

              // also update to the currently mouse overed feature
              this.areaMouseOverInfo.areaInfo = event;
              this.mouseFeature = event.feature;
              map.data.overrideStyle(this.mouseFeature, {
                strokeWeight: 3.5,
              });
            }
          }
        }.bind(this),
      );
    },
    getDataCount() {
      this.$axios
        .post("/get-data-count", this.viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            if (response.data > 0) {
              // call this for the information panel
              this.buildIndicatorQuintiles();
            } else {
              this.viewInfo.quintiles.q1_min = null;
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: 4000,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    buildIndicatorQuintiles(forceUpdate = true) {
      if (
        this.viewInfo.viewportInfo.dataLevel != this.latestQuintileLevel ||
        this.viewInfo.indicatorInfo.id != this.latestIndicatorId ||
        forceUpdate
      ) {
        this.viewInfo.quintiles = {
          q1_min: "loading",
        };

        this.$axios
          .get(
            "/standard-data-quintile-range/" +
              this.viewInfo.viewportInfo.ind_id +
              "/" +
              this.viewInfo.viewportInfo.dataLevel +
              "/" +
              this.viewInfo.viewportInfo.hotspotFlag,
          )
          .then((response) => {
            // handle success
            this.viewInfo.quintiles = response.data;
          })
          .catch(
            function (error) {
              // handle error
              console.error(error);
            }.bind(this),
          );

        // update our latest fetched quintile info
        this.latestQuintileLevel = this.viewInfo.viewportInfo.dataLevel;
        this.latestIndicatorId = this.viewInfo.indicatorInfo.id;
      }
    },
    handleDataLevelSelected(newValue) {
      this.selectedDataLevel = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    handleLockViewChanged(newValue) {
      this.lockedView = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    async getBoundaries() {
      this.emit.emit("systemBusy", true);

      // first clear out anything that's there
      if (this.clientBoundary.length > 0) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(null);
        });

        this.clientBoundary = [];
      }
      const { Polygon } = await google.maps.importLibrary("maps");

      this.$axios
        .post("/get-boundaries-discovery-tool", {
          area_ids: this.selectedLAorCLA,
        })
        .then((response) => {
          // handle success
          if (response.data.boundary) {
            const boundary = JSON.parse(response.data.boundary);

            // build an array of polygons, can be one, or many for a multiplygon
            let polygons = [];

            if (boundary.type === "Polygon") {
              // Polygon
              const googleMapsPaths = boundary.coordinates.map((ring) =>
                ring.map((coordinate) => {
                  return { lat: coordinate[1], lng: coordinate[0] };
                }),
              );

              polygons.push(
                new Polygon({
                  map,
                  paths: googleMapsPaths,
                  fillOpacity: 0,
                  strokeWeight: 4,
                  strokeColor: response.data.boundary_colour,
                  zIndex: 10,
                  clickable: false,
                }),
              );
              this.clientBoundary = polygons;
            } else {
              // MultiPolygon
              const multiCoordinates = boundary.coordinates;
              multiCoordinates.forEach((coords) => {
                const googleMapsPaths = coords.map((coord) => {
                  return coord.map((subCoord) => {
                    return { lat: subCoord[1], lng: subCoord[0] };
                  });
                });

                polygons.push(
                  new Polygon({
                    map,
                    paths: googleMapsPaths,
                    fillOpacity: 0,
                    strokeWeight: 4,
                    strokeColor: response.data.boundary_colour,
                    zIndex: 10,
                    clickable: false,
                  }),
                );
                this.clientBoundary = polygons;
              });
            }

            this.zoomToBoundaries().then(() => {
              // once we've got the boundaries and zoomed in let's display the data
              setTimeout(() => {
                this.getIndicators();
              }, 1500);
            });
          }
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    },
    async zoomToBoundaries() {
      // gather up all the bounds of any loaded polys
      var bounds = new google.maps.LatLngBounds();

      // iterate over each polygon in this.polygonObjects and extend the bounds
      this.clientBoundary.forEach(function (polygon) {
        polygon.getPath().forEach(function (latLng) {
          bounds.extend(latLng);
        });
      });

      // fit the map to the bounds to show all the polygons
      map.fitBounds(bounds);
    },
    zoomToDefaultView() {
      // get the clients default view
      const defaultZoom = this.$store.getters.customClientConfig.default_zoom;
      const defaultLat = this.$store.getters.customClientConfig.default_lat;
      const defaultLng = this.$store.getters.customClientConfig.default_lng;

      // if they have them ALL, set the map up to that
      if (defaultZoom && defaultLat && defaultLng) {
        if (!isNaN(defaultLat) && !isNaN(defaultLng)) {
          map.setZoom(defaultZoom);
          map.setCenter({
            lat: parseFloat(defaultLat),
            lng: parseFloat(defaultLng),
          });
        } else {
          console.error("Invalid coordinates for default view");
        }
      }
    },
  },
};
</script>

<style scoped></style>
