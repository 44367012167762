<template>
  <!-- class="ma-0 pa-0" fill-height -->
  <div>
    <AddMetaTable
      v-model:addMetaTableDialog="addMetaTableDialog"
      :chartRef="localItem.chartRef"
      v-on:updateTheme="$emit('updateTheme')"
    />
    <v-dialog v-model="confirmDialog" width="550">
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left"
            >Confirm Deletion?</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions>
          <v-card-text style="text-align: center">
            Are you sure you want to delete this chart? You <i>cannot</i> undo
            this action!
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            rounded="0"
            variant="elevated"
            @click="deleteItem(false)"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            rounded="0"
            variant="elevated"
            @click="deleteItem(true)"
            aria-label="Delete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="jsonExampleDialog" width="650">
      <v-card>
        <v-card-actions>
          <textarea v-model="jsonExample" rows="20" cols="60" readonly>
          </textarea>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            rounded="0"
            variant="elevated"
            @click="jsonExampleDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- configure chart -->
    <v-dialog v-model="configureChartDialog" width="700" persistent scrollable>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left"
            >Configure Chart</v-toolbar-title
          ></v-toolbar
        >
        <v-card-text style="height: 750px">
          <v-card-actions>
            <v-text-field
              v-model="localItem.chartDescription"
              label="Chart Title"
              title="Chart Title"
              variant="outlined"
              density="compact"
              rounded="0"
            ></v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-select
              v-model="localItem.syncChartWithHTMLTableRef"
              :items="HTMLTables"
              label="Sync with a HTML Table"
              variant="outlined"
              density="compact"
              rounded="0"
              class="select"
              align-center
              clearable
            >
            </v-select>
          </v-card-actions>
          <v-card-actions>
            <v-checkbox
              :disabled="
                localItem.chartXCommaSeperatedValues !== null ||
                localItem.chartYCommaSeperatedValues !== null ||
                localItem.timeSeries
                  ? true
                  : false
              "
              class="pa-0 ma-0"
              v-model="localItem.inverseChartData"
              label="Invert Chart Data"
            ></v-checkbox>
          </v-card-actions>
          <v-card-actions>
            <v-checkbox
              :disabled="
                localItem.chartXCommaSeperatedValues !== null ||
                localItem.chartYCommaSeperatedValues !== null
                  ? true
                  : false
              "
              class="pa-0 ma-0"
              v-model="localItem.timeSeries"
              label="Time Series Data"
              @click="localItem.inverseChartData = localItem.timeSeries"
            ></v-checkbox>
          </v-card-actions>
          <v-card-actions>
            <v-checkbox
              class="pa-0 ma-0"
              v-model="localItem.showComparators"
              label="Show Comparators"
            ></v-checkbox>
          </v-card-actions>
          <v-card-actions>
            <v-text-field
              v-model="localItem.chartYLabel"
              type="text"
              label="Chart Y Label"
              variant="outlined"
              density="compact"
              rounded="0"
              class="width"
            ></v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-text-field
              :disabled="localItem.syncChartWithHTMLTableRef"
              v-model="localItem.chartXCommaSeperatedValues"
              type="text"
              label="Chart X Axis Labels (Comma Seperated Values)"
              variant="outlined"
              density="compact"
              rounded="0"
              class="width"
            ></v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-textarea
              :disabled="localItem.syncChartWithHTMLTableRef"
              v-model="localItem.chartYCommaSeperatedValues"
              type="text"
              label="Chart Y Axis (Comma Seperated Values OR JSON)"
              variant="outlined"
              density="compact"
              rounded="0"
              class="width"
            ></v-textarea>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              style="margin-top: -29px"
              color="success"
              rounded="0"
              variant="elevated"
              size="small"
              @click="jsonExampleDialog = true"
              aria-label="Y Axis Json Example"
            >
              Y Axis Json Example
            </v-btn>
          </v-card-actions>
          <v-card-actions style="width: 100%">
            <v-table
              density="compact"
              rounded="0"
              style="width: 100%"
              v-if="
                localItem.syncChartWithHTMLTableRef === null &&
                localItem.chartYCommaSeperatedValues === null
              "
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">&nbsp;</th>
                    <th class="text-center" style="width: 60%">Indicator</th>
                    <th class="text-center">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(indicator, i) in localItem.indicators" :key="i">
                    <td>
                      <v-icon
                        style="cursor: pointer"
                        title="remove indicator"
                        @click="
                          removeIndicator(
                            reportThemeObject.id,
                            indicator.id,
                            indicator.chartRef,
                          )
                        "
                        >mdi-trash-can-outline</v-icon
                      >
                      <input
                        type="number"
                        class="orderField"
                        title="indicator order"
                        v-model.number="indicator.order"
                        @click="
                          updateIndicatorOrder(
                            reportThemeObject.id,
                            indicator.id,
                            indicator.chartRef,
                            indicator.order,
                          )
                        "
                      />
                    </td>
                    <td class="text-left">{{ indicator.indicatorName }}</td>
                    <td class="text-center">
                      {{ formatDate(indicator.date) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              :disabled="
                localItem.syncChartWithHTMLTableRef === null &&
                localItem.chartXCommaSeperatedValues === null
                  ? false
                  : true
              "
              color="success"
              rounded="0"
              variant="elevated"
              size="small"
              @click="addMetaTableDialog = true"
              aria-label="Add Indicator"
            >
              Add Indicator
            </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="error"
            rounded="0"
            variant="elevated"
            @click="configureChartDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
          <v-btn
            color="warning"
            rounded="0"
            variant="elevated"
            @click="$emit('setIndicatorValues')"
            aria-label="aggregate"
          >
            aggregate
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            rounded="0"
            variant="elevated"
            @click="$emit('updateTheme')"
            aria-label="Update"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddChartToReportTheme
      v-model:addChartDialog="addChartDialog"
      :changeChart="true"
      :configID="localItem.id"
      v-on:updateTheme="$emit('updateTheme')"
    />
    <v-card-actions>
      <v-row>
        <v-col cols="1">
          <v-select
            v-model="localItem.order"
            :items="pageItemCount"
            label="Position"
            variant="outlined"
            density="compact"
            rounded="0"
            class="select pt-0"
            align-center
            @change="$emit('updateTheme')"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Chart Name"
            variant="outlined"
            density="compact"
            rounded="0"
            class="pt-0"
            align-center
            v-model="localItem.itemName"
            @change="$emit('updateTheme')"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Chart Source"
            variant="outlined"
            density="compact"
            rounded="0"
            class="pt-0"
            align-center
            v-model="localItem.itemSource"
            @change="$emit('updateTheme')"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn
            color="success"
            rounded="0"
            variant="elevated"
            @click="$emit('updateTheme')"
            class="mr-6"
            aria-label="Save Changes"
          >
            Save Changes
          </v-btn>
          <v-btn
            color="error"
            rounded="0"
            variant="elevated"
            @click="deleteItem(null)"
            aria-label="Delete"
          >
            Delete
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-actions class="pt-0">
      <Charts
        :item="item"
        :indicatorValues="indicatorValues"
        :comparators="comparators"
        :comparatorsList="comparatorsList"
        :reportThemeObject="reportThemeObject"
        :load="load"
      />
    </v-card-actions>
    <v-card-actions class="pt-0">
      <v-btn
        color="success"
        class="ma-2"
        rounded="0"
        variant="elevated"
        size="x-small"
        @click="addChartDialog = true"
        aria-label="Change Chart Type"
        >Change Chart Type</v-btn
      >
      <v-btn
        color="success"
        class="ma-2"
        rounded="0"
        variant="elevated"
        size="x-small"
        @click="configureChartDialog = true"
        aria-label="Configure Chart"
        >Configure Chart</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
import AddMetaTable from "@/components/AddMetaTable";
import Charts from "@/components/Charts.vue";
import AddChartToReportTheme from "@/components/AddChartToReportTheme";
import { formatDate } from "@/mixins/FormatDate";

export default {
  name: "IDTE",
  data: () => ({
    formatDate: formatDate,
    colours: [
      "#006400",
      "#00008b",
      "#b03060",
      "#ff0000",
      "#ffff00",
      "#00ff00",
      "#00ffff",
      "#ff00ff",
      "#6495ed",
      "#ffdead",
    ],
    localItem: Object,
    addMetaTableDialog: false,
    addChartDialog: false,
    confirmDialog: false,
    jsonExampleDialog: false,
    displayChart: false,
    chartAxis: null,
    configureChartDialog: false,
    jsonExample: `
            HINT!!!!
            In the "data" fields below there are 12 peices of data...
            this means you will need 12 X Axis Labels!
            X Axis Lables Example:

                Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov,  Dec

            Copy EVERYTHING (including the square brackets) below this text:

            [
              {
                "label": "Data Set One",
                "backgroundColor": "#f87979",
                "data": [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
              }, 
              {
                "label": "Data Set Two",
                "backgroundColor": "green",
                "data": [30, 40, 12, 89, 30, 70, 69, 50, 60, 50, 32, 21]
              }
            ]`,
    chartConfig: {
      labels: [],
      datasets: [],
    },
    idleTimeout: null,
  }),
  components: {
    Charts,
    AddChartToReportTheme,
    AddMetaTable,
  },
  computed: {
    pageItemCount: {
      get() {
        let array = [];
        for (var i = 0; i < this.reportThemeObject.config.length; i++) {
          array.push(i);
        }
        return array;
      },
    },
    HTMLTables: {
      get() {
        let array = [];
        for (var i = 0; i < this.reportThemeObject.config.length; i++) {
          if (this.reportThemeObject.config[i].indicatorHTMLTableRef !== null) {
            array.push(this.reportThemeObject.config[i].indicatorHTMLTableRef);
          }
        }
        return array;
      },
    },
    reportThemeObject: {
      get() {
        return this.$store.state.reportThemeObject;
      },
      set(value) {
        this.$store.commit("setReportThemeObject", value);
      },
    },
    reportThemeObjectClone: {
      get() {
        return this.$store.state.reportThemeObjectClone;
      },
      set(value) {
        this.$store.commit("setReportThemeObjectClone", value);
      },
    },
  },
  props: {
    load: {
      type: Boolean,
      default: false,
    },
    indicatorValues: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    comparators: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    selectedComparators: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    comparatorsList: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.localItem = this.item;
  },
  methods: {
    deleteItem(state) {
      if (state === null) {
        this.confirmDialog = true;
      } else if (state === true) {
        this.localItem["delete"] = true;
        this.$emit("updateTheme");
        this.confirmDialog = false;
      } else {
        this.confirmDialog = false;
      }
    },
    updateIndicatorOrder(themeID, id, ref, order) {
      // clear the debouncer timeout
      clearTimeout(this.idleTimeout);
      // start the debouncer countdown again!
      this.idleTimeout = setTimeout(
        function () {
          let i = 0;
          let areadCode = null;
          let url =
            "reports-themes/update-indicator-order/" +
            themeID +
            "/" +
            id +
            "/" +
            ref +
            "/" +
            "htmlTable" +
            "/" +
            order;
          while (i < 3) {
            areadCode = null;
            if (typeof this.selectedComparators[i] !== "undefined") {
              areadCode = this.selectedComparators[i];
            }
            url = url + "/" + areadCode;
            i++;
          }
          this.emit.emit("systemBusy", true);
          this.$axios
            .put(url)
            .then(
              function (response) {
                // handle success
                this.reportThemeObject = response.data;
                this.reportThemeObjectClone = this.$cloneDeep(
                  this.reportThemeObject,
                );
                // this.$emit("setIndicatorValues");
                this.emit.emit("systemBusy", false);
              }.bind(this),
            )
            .catch(
              function (error) {
                this.emit.emit("systemBusy", false);
                // handle error
                console.error(error);
              }.bind(this),
            );
        }.bind(this),
        2000,
      );
    },
    removeIndicator(themeID, id, ref) {
      let i = 0;
      let areadCode = null;
      let url =
        "reports-themes/remove-indicator/" +
        themeID +
        "/" +
        id +
        "/" +
        ref +
        "/" +
        "chart";
      while (i < 3) {
        areadCode = null;
        if (typeof this.selectedComparators[i] !== "undefined") {
          areadCode = this.selectedComparators[i];
        }
        url = url + "/" + areadCode;
        i++;
      }
      this.emit.emit("systemBusy", true);
      this.$axios
        .delete(url)
        .then(
          function (response) {
            // handle success
            this.reportThemeObject = response.data;
            this.reportThemeObjectClone = this.$cloneDeep(
              this.reportThemeObject,
            );
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    comparitorName(comparator) {
      for (var i = 0; i < this.comparatorsList.length; i++) {
        if (this.comparatorsList[i].area_code === comparator) {
          return this.comparatorsList[i].area_name;
        }
      }
    },
  },
  watch: {
    // watch for changes in the item prop
    item(newVal) {
      this.localItem = newVal;
    },
    // keep the parent in sync. We do this because of the mutation issue
    localItem(newVal) {
      this.$emit("update:item", newVal);
    },
  },
};
</script>

<style scoped>
.orderField {
  width: 40px;
  margin: 5px;
  border: 1px solid #000000;
  background-color: #ffffff;
  text-align: center;
}
</style>
