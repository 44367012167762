<template>
  <div class="text-center">
    <v-dialog v-model="computedMapAboutIndDialog" max-width="700px" scrollable>
      <v-card style="height: 75vh">
        <v-toolbar
          :style="
            'max-height: 60px; ' +
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-center"
        >
          <v-spacer>
            <v-toolbar-title class="text-start">
              <v-btn
                icon
                dark
                @click="computedMapAboutIndDialog = false"
                aria-label="Close"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              Indicator Information</v-toolbar-title
            >
          </v-spacer>
        </v-toolbar>
        <!-- loading UX -->
        <v-card-actions v-if="!viewInfo" tile elevation="0" class="progress">
          <div class="progressText">
            <v-progress-circular
              :size="200"
              :width="2"
              color="#51627C"
              indeterminate
              >Loading Information</v-progress-circular
            >
          </div>
        </v-card-actions>
        <v-card-text class="min-accessible-size" v-else>
          <div class="indInfoHeader"><b>Indicator Name</b></div>
          <div class="indInfoText">
            {{ viewInfo.indicatorInfo.indicator_name }}
          </div>
          <div class="indInfoHeader"><b>Description</b></div>
          <div class="indInfoText">
            {{ viewInfo.indicatorInfo.indicator_description }}
          </div>
          <div class="indInfoHeader"><b>Date</b></div>
          <div class="indInfoText">
            {{ viewInfo.indicatorInfo.date_coverage_text }}
          </div>
          <div class="indInfoHeader"><b>How often Updated</b></div>
          <div class="indInfoText">
            {{ viewInfo.indicatorInfo.update_frequency }}
          </div>
          <div class="indInfoHeader"><b>Source</b></div>
          <div class="indInfoText">
            {{ viewInfo.indicatorInfo.source }}
          </div>
        </v-card-text>
        <v-divider v-if="viewInfo"></v-divider>
        <v-card-actions v-if="viewInfo">
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            tile
            color="error"
            @click="computedMapAboutIndDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MAPABOUTIND",
  data: () => ({}),
  computed: {
    computedMapAboutIndDialog: {
      get() {
        return this.mapAboutIndDialog;
      },
      set(val) {
        this.$emit("update:mapAboutIndDialog", val);
      },
    },
    headerColour() {
      return this.$store.state.config.siteConfig.toolbar_colour;
    },
  },
  components: {
    // FormsInputsAndControls
  },
  props: {
    mapAboutIndDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    viewInfo: null,
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 300px;
}
.progress {
  margin-top: 150px;
  width: 100%;
}

.indInfoHeader {
  margin: 2px;
  padding: 0;
}

.indInfoText {
  margin: 2px;
  margin-bottom: 20px !important;
  padding: 0;
}
</style>
