<template>
  <div class="text-center">
    <v-dialog
      v-if="viewInfo.indicatorInfo"
      v-model="computedMapYourAreasDialog"
      max-width="850px"
      scrollable
    >
      <v-card min-height="80vh">
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
          max-height="70px"
        >
          <v-spacer>
            <v-toolbar-title
              class="white--text pa-4"
              style="white-space: normal"
            >
              <v-btn
                icon
                dark
                @click="computedMapYourAreasDialog = false"
                aria-label="Close"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              {{ viewInfo.indicatorInfo.indicator_name }}
            </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-card-text class="py-2" style="overflow-y: hidden">
          <v-card-text
            v-if="
              viewInfo.indicatorInfo.lowest_show_level == 6 && !loadingContent
            "
            class="py-2 my-4 pl-0 pr-0"
          >
            <b>Please note:</b> This dataset is published at Local Authority
            level. For the purpose of accuracy, any custom areas that contain
            constituent regions smaller than Local Authority will not show data
            in the tables and dashboard.
          </v-card-text>
          <div class="table-container" v-if="!loadingContent">
            <v-data-table
              :group-by="groupBy"
              :headers="dynamicHeaders"
              :items="areas"
              item-value="name"
              :items-per-page="-1"
              class="scrollable-table"
            >
              <template
                v-slot:group-header="{
                  item,
                  columns,
                  toggleGroup,
                  isGroupOpen,
                }"
              >
                <template
                  :ref="
                    (el) => {
                      if (!isGroupOpen(item)) toggleGroup(item);
                    }
                  "
                ></template>
                <tr class="table-sub-header">
                  <td :colspan="columns.length">
                    {{ item.value }}
                  </td>
                </tr>
              </template>
              <template #bottom></template>
            </v-data-table>
          </div>
          <div v-if="loadingContent" class="py-2 text-center">
            <v-progress-circular
              indeterminate
              :size="200"
              color="#51627C"
              style="margin-top: 160px"
              >Aggregating...</v-progress-circular
            >
          </div>
          <v-card v-if="!loadingContent">
            <v-card-actions>
              <v-btn
                tile
                :disabled="loadingContent"
                color="success"
                variant="elevated"
                @click="downloadYourAreasData"
                aria-label="download CSV"
              >
                download CSV
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                tile
                color="error"
                variant="elevated"
                @click="computedMapYourAreasDialog = false"
                aria-label="close"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "MAPYOURAREAS",
  data() {
    return {
      groupBy: [
        {
          key: "category",
          order: "asc",
        },
      ],
      headers: [
        {
          title: "Your Areas",
          align: "start",
          sortable: false,
          key: "name",
        },
        { title: "Value", key: "value" },
        { title: "(N)", key: "n" },
      ],
      areas: [],
      loadingContent: false,
    };
  },
  props: {
    mapYourAreasDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    viewInfo: null,
  },
  methods: {
    getYourAreasData() {
      this.loadingContent = true;
      // API call to get your areas data
      this.$axios
        .get("/map/your-areas/" + this.viewInfo.indicatorInfo.id)
        .then((response) => {
          // handle success
          this.loadingContent = false;
          this.areas = response.data;
        })
        .catch((error) => {
          // handle error
          this.loadingContent = false;
          console.log(error);
        });
    },
    downloadYourAreasData() {
      const clientName =
        this.$store.state.config.customClientConfig.client_name;
      const headers = [clientName];
      const formattedData = [];

      formattedData.push([""]);
      formattedData.push([
        "Indicator Name",
        this.viewInfo.indicatorInfo.indicator_name,
      ]);
      formattedData.push([
        "Description",
        this.viewInfo.indicatorInfo.indicator_description,
      ]);
      formattedData.push(["Date", new Date().toDateString()]);
      formattedData.push([""]);

      formattedData.push(["Category", "Area", "Value", "(N)"]);

      this.areas.forEach((area) => {
        formattedData.push([area.category, area.name, area.value, area.n]);
      });

      const fileTitle =
        "Data Export " +
        clientName +
        " " +
        this.viewInfo.indicatorInfo.indicator_name;

      // call the exportCSVFile mixin
      exportCSVFile(headers, formattedData, fileTitle);
    },
  },
  mounted() {},
  computed: {
    computedMapYourAreasDialog: {
      get() {
        return this.mapYourAreasDialog;
      },
      set(val) {
        this.$emit("update:mapYourAreasDialog", val);
      },
    },
    dynamicHeaders() {
      return [
        {
          title: "",
          key: "data-table-group",
          width: "0px",
          align: "center",
          sortable: false,
        },
        {
          title: this.viewInfo.indicatorInfo.indicator_name || "Your Areas",
          align: "start",
          sortable: false,
          key: "name",
        },
        { title: "Value", key: "value", sortable: false },
        { title: "(N)", key: "n", sortable: false },
      ];
    },
  },
  watch: {
    "viewInfo.indicatorInfo.id": {
      handler: function () {
        this.getYourAreasData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.table-sub-header {
  background-color: rgba(245, 245, 245, 0.432);
  font-weight: bold;
}

.table-container {
  max-height: 65vh;
  overflow-y: auto;
}

.scrollable-table .v-data-table__wrapper {
  max-height: none !important;
}
</style>
