<template>
  <v-container id="mapContainer" fluid class="pa-2">
    <h1 class="h1" style="display: none">Map</h1>
    <MapNavigate
      v-model:mapNavigateDialog="mapNavigateDialog"
      class="navigateElement"
    />
    <MapData
      v-model:mapDataDialog="mapDataDialog"
      @selectedIndicator="updateSelectedIndicator"
      v-model:selectedIndicator="selectedIndicator"
    />
    <MapServices
      :serviceTypeIds="serviceTypeIds"
      v-model:mapServicesDialog="mapServicesDialog"
      @closeServicesDialog="mapServicesDialog = false"
      v-model:loadAllServices="loadAllServices"
      v-model:customAreaCount="customAreaCount"
      v-model:clearDialogs="clearDialogs"
      @selectedTypes="loadingDelay"
    />
    <MapReports v-model:mapReportsDialog="mapReportsDialog" />
    <CustomAreaSelector
      v-model:mapCustomAreasDialog="mapCustomAreasDialog"
      v-model:markerCount="markerCount"
      v-model:clearDialogs="clearDialogs"
      @selectedCustomAreas="getCustomAreaPolygons"
    />
    <MapSettings
      v-model:mapSettingsDialog="mapSettingsDialog"
      v-model:hotspotFlag="hotspotFlag"
      v-model:boundaryFlag="boundaryFlag"
    />
    <v-row>
      <v-col cols="12" class="ma-0 pa-0">
        <!-- the map container -->
        <v-card
          tile
          style="overflow: initial; z-index: initial"
          class="ma-0 pa-0"
        >
          <!-- container for map Legend -->
          <div>
            <v-card class="mapLegend" elevation="0">
              <MapInfo
                v-model:mapInfoDialog="mapInfoDialog"
                :viewInfo="viewInfo"
                :areaMouseOverInfo="areaMouseOverInfo"
                :colourScheme="colourScheme"
                :lockedView="lockedView"
                @lockViewChanged="handleLockViewChanged"
                @selectedDataLevelChanged="handleDataLevelSelected"
              />
              <CustomAreaLegend
                :customAreas="selectedCustomAreas"
                @zoomToArea="zoomToArea"
                @toggleArea="toggleCustomAreaFromMap"
              />
              <ServiceTypesLegend
                :selectedTypes="selectedTypes"
                :loadingServices="loadingServices"
                :serviceLoadPercentage="serviceLoadPercentage"
                @zoomToServiceType="zoomToServiceType"
              />
            </v-card>
          </div>
          <!-- container for map buttons -->
          <div class="mapButtons">
            <v-tooltip right>
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapNavigateButton"
                  class="mapButton"
                  fab
                  icon="mdi-map-search-outline"
                  v-bind="props"
                  @click="mapNavigateDialog = true"
                  aria-label="navigate"
                ></v-btn>
              </template>
              <template #default>
                <span>Navigate</span>
              </template>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapDataButton"
                  class="mapButton"
                  fab
                  icon="mdi-layers-plus"
                  v-bind="props"
                  @click="mapDataDialog = true"
                  aria-label="data"
                >
                </v-btn>
              </template>
              <span>Data</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapServicesButton"
                  class="mapButton"
                  fab
                  icon="mdi-map-marker"
                  v-bind="props"
                  @click="
                    sendAnalytics('view_services_on_map');
                    mapServicesDialog = true;
                  "
                  aria-label="services"
                >
                </v-btn>
              </template>
              <span>Services</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapReportsButton"
                  class="mapButton"
                  fab
                  icon="mdi-file-chart-outline"
                  v-bind="props"
                  @click="mapReportsDialog = true"
                  aria-label="reports"
                >
                </v-btn>
              </template>
              <span>Reports</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapCustomAreasButton"
                  class="mapButton"
                  fab
                  icon="mdi-vector-polygon"
                  v-bind="props"
                  @click="
                    sendAnalytics('view_custom_areas_on_map');
                    mapCustomAreasDialog = true;
                  "
                  aria-label="custom areas"
                >
                </v-btn>
              </template>
              <span>Custom Areas</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapSettingsButton"
                  class="mapButton"
                  fab
                  icon="mdi-cog"
                  v-bind="props"
                  @click="mapSettingsDialog = true"
                  aria-label="settings"
                >
                </v-btn>
              </template>
              <span>Settings</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapClearMapButton"
                  class="mapButton"
                  fab
                  icon="mdi-layers-off"
                  v-bind="props"
                  @click="clearMap()"
                  aria-label="clear map"
                >
                </v-btn>
              </template>
              <span>Clear Map</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ props }">
                <v-btn
                  id="mapShareMapButton"
                  class="mapButton"
                  fab
                  icon="mdi-share-variant"
                  v-bind="props"
                  @click="
                    shareMap();
                    shareDialog = true;
                  "
                  aria-label="share map"
                >
                </v-btn>
              </template>
              <span>Share Map</span>
            </v-tooltip>
            <v-dialog v-model="shareDialog" width="600" id="shareMapDialog">
              <v-card-title
                :style="
                  'background-color: ' +
                  this.$store.state.config.siteConfig.toolbar_colour
                "
                class="text-h6 pa-4"
              >
                Share Map
              </v-card-title>
              <v-card tile>
                <v-card-text
                  v-if="!$store.state.config.siteConfig.is_public_site"
                  class="ps-5 pb-0"
                  >Important: Shared links of the map can only be accessed by
                  individuals who have both a Local Insight account and are
                  members of your organisation
                </v-card-text>
                <v-card-actions class="justify-start ps-5 mt-3">
                  <v-progress-circular
                    v-if="loadingShareableLink"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <span class="ml-2" v-if="loadingShareableLink"
                    >Creating shareable link...</span
                  >
                  <v-text-field
                    v-else
                    :value="shareUrl"
                    type="text"
                    readonly
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    @click="this.$refs.shareUrlField.select()"
                    append-icon="mdi-content-copy"
                    label:append-icon="Click to Copy Link"
                    @click:append="copyToClipboard"
                    :hint="clipboardMessage"
                    persistent-hint
                    ref="shareUrlField"
                  ></v-text-field>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card class="pa-1 pb-2">
                  <v-btn
                    color="primary"
                    tile
                    class="pa-2 mt-1 ml-2"
                    title="back to list"
                    @click="shareDialog = false"
                    aria-label="back"
                  >
                    back
                  </v-btn>
                </v-card>
              </v-card>
            </v-dialog>
          </div>
          <div style="width: 100%; height: 96vh">
            <div id="pano"></div>

            <div
              ref="mapCanvas"
              id="mapCanvas"
              class="map-canvas"
              style="width: 100%; height: 96vh"
            ></div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* global google */

//components for the map options
import MapData from "@/components/MapData";
import MapInfo from "@/components/MapInfo";
import CustomAreaLegend from "@/components/CustomAreaLegend";
import ServiceTypesLegend from "@/components/ServiceTypesLegend";
import MapServices from "@/components/MapServices";
import MapReports from "@/components/MapReports";
import CustomAreaSelector from "@/components/CustomAreaSelector";
import MapSettings from "@/components/MapSettings";
import MapNavigate from "@/components/MapNavigate";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { loadGoogleMaps } from "@/mixins/LoadGoogleMaps";
import { toRaw } from "vue";
import { getURLParam } from "@/mixins/GetURLParam";
import { copyToClipboard } from "@/mixins/CopyToClipboard";

// store map here so vue doesn't wrap it in a proxy - Google doesn't like that.
let map = null;

export default {
  name: "GoogleMap",
  data: () => ({
    clipboardMessage: "",
    savedMapId: null,
    serviceTypeIds: [],
    loadingShareableLink: false,
    shareDialog: false,
    shareUrl: "",
    mapsApiLoaded: false,
    builtIcons: {},
    lockedView: false,
    icons: [],
    clearing: false,
    loadingServices: false,
    currentDataLevel: null,
    polygonsWithDataset: null,
    selectedIndicator: null,
    selectedTypes: null,
    loadedTypes: null,
    clearDialogs: false,
    selectedCustomAreas: [],
    mouseFeature: null,
    mapDataDialog: false,
    mapInfoDialog: false,
    mapServicesDialog: false,
    mapReportsDialog: false,
    mapCustomAreasDialog: false,
    mapSettingsDialog: false,
    mapNavigateDialog: false,
    hotspotFlag: "all",
    zoom: 4,
    latestQuintileLevel: 99,
    latestIndicatorId: 0,
    center: { lat: null, lng: null },
    hasDefaultView: false,
    settingDefaultView: false,
    resettingDefaultView: false,
    firstLoad: false,
    maxZoomLevelForClustering: 17,
    zoomAreaLevels: {
      1: 6,
      2: 6,
      3: 6,
      4: 6,
      5: 6,
      6: 6,
      7: 6,
      8: 6, // LA
      9: 5,
      10: 5, //Ward
      11: 2, // MSOA
      12: 2,
      13: 1, // LSOA
      14: 1,
      15: 1,
      16: 1,
      17: 1,
      18: 1,
      19: 1,
      20: 1,
      21: 1,
      22: 1,
    },
    loadAllServices: false,
    customAreaServiceIds: [],
    markers: [],
    infoWindows: {},
    currentMarkers: [],
    markerCluster: null,
    polygonObjects: [],
    data_layer: null,
    idleTimeout: null,
    areaMouseOverInfo: {
      areaInfo: null,
      freeze: false,
    },
    viewInfo: {
      viewportInfo: {},
      quintiles: { q1_min: "loading" },
      indicatorInfo: {
        id: null,
      },
      numeratorInfo: {},
    },
    mapOptions: {
      gestureHandling: "greedy",
    },
    colourScheme: [],
    clientBoundary: [],
    boundaryFlag: false,
    serviceLoadPercentage: 0,
    selectedDataLevel: null,
  }),
  components: {
    MapData,
    MapInfo,
    CustomAreaLegend,
    ServiceTypesLegend,
    MapServices,
    MapReports,
    CustomAreaSelector,
    MapSettings,
    MapNavigate,
  },
  props: {
    model: null,
    item: {},
    reportMapPrimaryAreaCode: null,
    relatedModelResults: {},
  },
  computed: {
    userHasAccess() {
      // Check if it's on staging
      return (
        window.location.hostname.includes("stg") ||
        window.location.hostname.includes("dev")
      );
    },
    showLegend() {
      if (this.polygonObjects.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    navigateResult: {
      get() {
        return this.$store.state.navigateResult;
      },
      set(value) {
        this.$store.commit("setNavigateResult", value);
      },
    },
    scaleOfScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0.5;
        case "sm":
          return 0.6;
        case "md":
          return 0.8;
        case "lg":
          return 0.8;
        case "xl":
          return 0.9;
        default:
          return 0.5;
      }
    },
    marginBottom() {
      return this.$vuetify.breakpoint.name === "md" ? "0" : "auto";
    },
    markerCount() {
      return this.markers.length;
    },
    customAreaCount() {
      return this.selectedCustomAreas.length;
    },
  },
  mounted() {
    this.zoom = this.$store.state.config.siteConfig.initialMapZoom.zoom;
    this.center.lat = this.$store.state.config.siteConfig.initialMapZoom.lat;
    this.center.lng = this.$store.state.config.siteConfig.initialMapZoom.lng;
    // If there is a saved map, attempt to fill the settings
    this.savedMapId = getURLParam("savedmap");
    if (this.savedMapId) {
      this.emit.emit("systemBusy", true);
      this.$axios
        .get("/saved-map/" + this.savedMapId)
        .then((response) => {
          const responseData = response.data.data;
          this.selectedIndicator = responseData.indicator_id;
          this.center = responseData.center;
          this.zoom = responseData.zoom;
          this.lockedView = responseData.locked_view;
          this.selectedCustomAreas = responseData.custom_area_ids;
          this.serviceTypeIds = responseData.service_type_ids;
          this.hotspotFlag = responseData.hotspot_flag;
          this.viewInfo.viewportInfo.dataLevel = responseData.data_level;
          this.boundaryFlag = responseData.client_boundary;
          this.emit.emit("systemBusy", false);

          if (response.data.message) {
            this.emit.emit("systemMessage", {
              title: "Error",
              message: response.data.message,
              timeout: 5000,
              colour: "error",
            });
          }

          // Call the functions to load the relevant data
          this.updateSelectedIndicator(this.selectedIndicator);
          this.loadMap();
          this.getCustomAreaPolygons(this.selectedCustomAreas);
        })
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              title: "Error! Failed to load map",
              message: error.responseData.message,
              timeout: -1,
              colour: "error",
            });
            this.loadMap();
          }.bind(this),
        );
    } else {
      this.loadMap();
    }

    // set up listener for clicking on streetview
    const wrapper = document.getElementById("mapContainer");

    wrapper.addEventListener("click", (event) => {
      // make sure it's a button that's been pressed
      const isStreetViewButton = event.target.id === "streetViewButton";
      if (!isStreetViewButton) {
        return;
      }

      this.openStreetView(
        event.target.getAttribute("lat"),
        event.target.getAttribute("lng"),
      );
    });
  },

  methods: {
    loadMap() {
      loadGoogleMaps(this.center, this.zoom).then((loadedMap) => {
        map = loadedMap;
        setTimeout(() => {
          this.addAltTextToIframe();
        }, 2000);
        this.mapSetup();
      });
    },
    addAltTextToIframe() {
      var text = "Interactive Map showing selected location based data";
      // Find all iframe elements on the page
      var iframes = document.querySelectorAll("iframe");
      // Loop through each iframe and add the alternative text
      iframes.forEach(function (iframe) {
        iframe.setAttribute("title", text);
        iframe.setAttribute("alt", text);
      });
    },
    shareMap() {
      this.loadingShareableLink = true;

      const params = {
        indicator_id: this.selectedIndicator,
        center: map.getCenter(),
        zoom: map.getZoom(),
        locked_view: this.lockedView,
        custom_area_ids: this.selectedCustomAreas,
        service_types: this.selectedTypes ?? [],
        hotspot_flag: this.hotspotFlag,
        data_level: this.viewInfo.viewportInfo.dataLevel,
        client_boundary: this.clientBoundary.length > 0,
      };

      this.$axios
        .post("/saved-map/store", params)
        .then((response) => {
          this.shareUrl = `${window.location.origin}/#/map?savedmap=${response.data.id}`;
          this.loadingShareableLink = false;
        })
        .catch((error) => {
          console.error("Error saving map: ", error);
        });
    },
    async copyToClipboard() {
      if (copyToClipboard(this.shareUrl)) {
        this.clipboardMessage = "The link has been copied to your clipboard";
        setTimeout(() => {
          this.clipboardMessage = "";
        }, 3000);
      } else {
        this.clipboardMessage =
          "The value has NOT been copied to your clipboard";
        setTimeout(() => {
          this.clipboardMessage = "";
        }, 3000);
      }
    },
    zoomToServiceType(type) {
      // Check if there are markers to zoom to
      if (this.markers.length > 0) {
        // get the bounds of the points
        var bounds = new google.maps.LatLngBounds();

        // get the extent of visible markers
        this.markers.forEach(function (marker) {
          if (marker.type_id === type.id) {
            bounds.extend(marker.position);
          }
        });

        // fit the map to show them
        map.fitBounds(bounds);
      }
    },
    toggleCustomAreaFromMap(area) {
      // toggle the visiblity of the polygon for this area
      this.polygonObjects.forEach((polygon) => {
        if (polygon.customAreaId === area.id) {
          toRaw(polygon).setVisible(area.visible);
        }
      });
    },
    zoomToArea(area) {
      //zoom to all areas in this.polygonObjects which has the customAreaId the same as area.id
      const bounds = new google.maps.LatLngBounds();
      this.polygonObjects.forEach((polygon) => {
        if (polygon.customAreaId === area.id) {
          polygon.getPath().forEach((latLng) => {
            bounds.extend(latLng);
          });
        }
      });
      map.fitBounds(bounds);
    },
    handleLockViewChanged(newValue) {
      this.lockedView = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    handleDataLevelSelected(newValue) {
      this.selectedDataLevel = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    openStreetView(lat, lng) {
      const streetViewLocation = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };

      // create a new Streetview instance
      const panorama = new google.maps.StreetViewPanorama(map.getDiv(), {
        position: streetViewLocation,
        pov: {
          heading: 34,
          pitch: 10,
        },
      });

      // allow close option
      panorama.setOptions({
        enableCloseButton: true,
      });

      // set the map to the streetview - controls in top left
      map.setStreetView(panorama);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push();
    },
    loadServiceTypeIcons() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/service-type-icons")
          .then((response) => {
            this.icons = response.data;
            resolve(response.data); // Resolve the promise with the response data
          })
          .catch((error) => {
            console.error(error);
            reject(error); // Reject the promise with the error
          });
      });
    },
    clearMap() {
      this.clearing = true;
      this.hotspotFlag = "all";
      this.selectedIndicator = null;
      this.viewInfo.indicatorInfo.id = null;
      this.viewInfo.viewportInfo.ind_id = null;
      this.selectedCustomAreas = [];
      this.selectedTypes = [];
      this.areaMouseOverInfo.areaInfo = null;
      this.areaMouseOverInfo.freeze = false;
      this.customAreaServiceIds = [];
      this.removeMarkers();
      this.removePolygons();
      this.clearDialogs = true;
      this.markerCluster = null;
      this.clientBoundary.forEach((polygon) => {
        toRaw(polygon).setMap(null);
      });
      this.boundaryFlag = false;

      // remove data
      let dataFeatures = map.data;
      dataFeatures.forEach(function (dataFeature) {
        dataFeatures.remove(dataFeature);
      });

      this.clearing = false;
      // reset the flag passed in
      setTimeout(() => {
        this.clearDialogs = false;
      }, 500);
    },
    getPolygonsWithDataset() {
      this.emit.emit("systemBusy", true);
      // add the the hotspot flag go determine what data to get back
      this.viewInfo.viewportInfo.hotspotFlag = this.hotspotFlag;

      // set the colours
      var colors = this.colourScheme;
      var color;

      this.$axios
        .post("/data-geometries", this.viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            this.polygonsWithDataset = response.data;

            let features = map.data;
            features.forEach(function (feature) {
              features.remove(feature);
            });
            if (this.polygonsWithDataset.features) {
              map.data.addGeoJson(this.polygonsWithDataset);
              this.addDataLayerListeners();
              map.data.setStyle(function (feature) {
                var quintile = feature.getProperty("quintile");
                switch (quintile) {
                  case 1:
                    color = "#" + colors[0];
                    break;
                  case 2:
                    color = "#" + colors[1];
                    break;
                  case 3:
                    color = "#" + colors[2];
                    break;
                  case 4:
                    color = "#" + colors[3];
                    break;
                  case 5:
                    color = "#" + colors[4];
                    break;
                  default:
                    color = "#" + colors[4];
                }
                return {
                  fillColor: color,
                  fillOpacity: 0.5,
                  strokeColor: "#FFFFFF",
                  strokeWeight: 0.5,
                };
              });
            }
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: 4000,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    buildIndicatorQuintiles(forceUpdate = true) {
      if (
        this.viewInfo.viewportInfo.dataLevel != this.latestQuintileLevel ||
        this.viewInfo.indicatorInfo.id != this.latestIndicatorId ||
        forceUpdate
      ) {
        this.viewInfo.quintiles = {
          q1_min: "loading",
        };

        this.$axios
          .get(
            "/standard-data-quintile-range/" +
              this.viewInfo.viewportInfo.ind_id +
              "/" +
              this.viewInfo.viewportInfo.dataLevel +
              "/" +
              this.viewInfo.viewportInfo.hotspotFlag,
          )
          .then((response) => {
            // handle success
            this.viewInfo.quintiles = response.data;
          })
          .catch(
            function (error) {
              // handle error
              console.error(error);
            }.bind(this),
          );

        // update our latest fetched quintile info
        this.latestQuintileLevel = this.viewInfo.viewportInfo.dataLevel;
        this.latestIndicatorId = this.viewInfo.indicatorInfo.id;
      }
    },
    getDataCount() {
      this.$axios
        .post("/get-data-count", this.viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            if (response.data > 0) {
              // call this for the information panel
              this.buildIndicatorQuintiles();
            } else {
              this.viewInfo.quintiles.q1_min = null;
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: 4000,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    async loadingDelay(types) {
      this.loadingServices = true;
      setTimeout(async () => {
        this.selectedTypes = types.filter((type) => type.isSelected);
        await this.loadServiceTypeIcons(); // Ensure this method is awaited
        this.loadSelectedTypes();
      }, 500);
    },
    clearMarkers() {
      // Clear markers from the map
      this.markers.forEach((marker) => marker.setMap(null));
      this.markers = [];

      // Clear the clusterer
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
      }
    },
    async loadSelectedTypes() {
      this.emit.emit("systemBusy", {
        busy: true,
        showProgressLoader: false,
      });
      this.loadingServices = true;

      // clear everything currently loaded
      this.removeMarkers();

      let pointsToLoad = [];

      // run through every selected type and add their services to the list
      this.selectedTypes.forEach((type) => {
        pointsToLoad = [].concat(pointsToLoad, type.services);
      });

      // then check if we have any polygons, and if we do, only take their services
      if (this.polygonObjects.length > 0) {
        pointsToLoad = pointsToLoad.filter((point) =>
          this.customAreaServiceIds.includes(point.id),
        );
      }

      // keep a track of postcodes we've already processed in case we get duplicates we need to offset
      let processedPostcodes = [];

      // get the libraries we need
      const { AdvancedMarkerElement } =
        await google.maps.importLibrary("marker");
      const { InfoWindow } = await google.maps.importLibrary("maps");

      // run through each point
      for (let i = 0; i < pointsToLoad.length; i++) {
        // grab the top of the list
        let point = pointsToLoad[i];

        // check if we have an icon built for this type, if not, make one
        if (typeof this.builtIcons[point.type_id] == "undefined") {
          let svgContent =
            '<svg xmlns="http://www.w3.org/2000/svg" height="25px" width="25px"><path d="' +
            this.icons[point.icon].svg_path +
            '" fill="' +
            point.colour +
            '"/></svg>';

          // convert the svg to a png
          const blob = await this.svgToPng(svgContent, 25, 25);
          const iconUrl = URL.createObjectURL(blob);

          this.builtIcons[point.type_id] = iconUrl;
        }

        // check if we've already processed this postcode somewhere
        if (processedPostcodes.includes(point.postcode)) {
          // if we have, offset the coordinates
          point.lat = parseFloat(point.lat) + this.getRandomOffset();
          point.lng = parseFloat(point.lng) + this.getRandomOffset();
        } else {
          // if we've not processed this one before, keep track of it for next time
          processedPostcodes.push(point.postcode);
        }

        let markerIcon = this.builtIcons[point.type_id];

        // Create an HTMLImageElement for the marker content
        let imgElement = document.createElement("img");
        imgElement.src = markerIcon;
        imgElement.width = 25;
        imgElement.height = 25;
        imgElement.id = point.id;
        imgElement.setAttribute("map-marker", "true");

        let marker = new AdvancedMarkerElement({
          position: { lat: parseFloat(point.lat), lng: parseFloat(point.lng) },
          content: imgElement,
        });

        // add  our custom propertes
        marker.id = point.id;
        marker.type_id = point.type_id;

        marker.addListener(
          "click",
          () => {
            if (!this.infoWindows[point.id]) {
              this.infoWindows[point.id] = new InfoWindow({
                content: "",
                maxWidth: 200,
              });
            }

            const infoWindow = this.infoWindows[point.id];
            infoWindow.setContent(this.getInfoContent(point));
            infoWindow.setPosition(marker.position);
            infoWindow.open(map, marker);
          },
          { passive: true },
        );

        // add it to the array to refer back to later
        this.markers.push(marker);

        //nullify the point to free up memory
        pointsToLoad[i] = null;

        // update the loading percentage
        this.serviceLoadPercentage = Math.round(
          (i / pointsToLoad.length) * 100,
        );

        // every 10% of the way through, take a break
        if (i % Math.round(pointsToLoad.length / 10) === 0) {
          await new Promise((resolve) => setTimeout(resolve, 0));
        }
      }

      // clear out the postcodes we've processed
      processedPostcodes = [];

      // add them to the clusterer
      this.markerCluster = new MarkerClusterer({
        markers: this.markers,
        map,
      });

      this.markerCluster.addListener("clusteringbegin", () => {
        this.loadingServices = true;
      });

      this.markerCluster.addListener("clusteringend", () => {
        this.loadingServices = false;
      });

      if (this.markers.length > 0 && !this.firstLoad && !this.savedMapId) {
        this.zoomToPoints();
      }
      this.firstLoad = false;
      this.loadingServices = false;
      this.emit.emit("systemBusy", false);
    },
    svgToPng(svgContent, width, height) {
      return new Promise((resolve, reject) => {
        const svgBlob = new Blob([svgContent], {
          type: "image/svg+xml;charset=utf-8",
        });
        const url = URL.createObjectURL(svgBlob);

        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            URL.revokeObjectURL(url);
            resolve(blob);
          }, "image/png");
        };
        img.onerror = (err) => {
          URL.revokeObjectURL(url);
          reject(err);
        };
        img.src = url;
      });
    },
    addDataLayerListeners() {
      // add listener to see if anything changes
      // mouse over to show the area & value
      map.data.addListener(
        "mouseover",
        function (event) {
          if (!this.areaMouseOverInfo.freeze) {
            //update the data with the event, which includes the mouse overed feature
            this.areaMouseOverInfo.areaInfo = event;

            // take a note of the feature clicked
            this.mouseFeature = event.feature;

            // up the border as a highlight
            map.data.overrideStyle(this.mouseFeature, {
              strokeWeight: 3.5,
            });
          }
        }.bind(this),
      );

      map.data.addListener(
        "mouseout",
        function () {
          //update the data with the event, which includes the mouse overed feature
          if (!this.areaMouseOverInfo.freeze) {
            this.areaMouseOverInfo.areaInfo = null;

            // reset border
            map.data.overrideStyle(this.mouseFeature, {
              strokeWeight: 0.5,
            });
          }
        }.bind(this),
      );

      // click to freeze the selection
      map.data.addListener(
        "click",
        function (event) {
          // update the data with the event, which includes the mouse overed feature
          // work around for now to avoid propagation
          if (event.alreadyCalled_) {
            //catch second click propagated up to 'map' from the data_layer
          } else {
            event.alreadyCalled_ = true;
            this.areaMouseOverInfo.freeze = !this.areaMouseOverInfo.freeze;

            // if we've just set it false, remove the area highlight
            if (!this.areaMouseOverInfo.freeze) {
              map.data.overrideStyle(this.mouseFeature, {
                strokeWeight: 0.5,
              });

              // also update to the currently mouse overed feature
              this.areaMouseOverInfo.areaInfo = event;
              this.mouseFeature = event.feature;
              map.data.overrideStyle(this.mouseFeature, {
                strokeWeight: 3.5,
              });
            }
          }
        }.bind(this),
      );
    },
    getInfoContent(feature) {
      const postcode = feature.postcode;
      const labels = feature.labels;

      // Add a style attribute for left text alignment
      let content = `<div style="text-align: left;">`;
      if (this.$store.state.config.siteConfig.site_country !== "aus") {
        // Add postcode with left alignment
        content += `<div><h4>Postcode:</h4> ${postcode}</div>`;
      }

      // Run through any labels and add them all in, aligned to the left
      for (const key in labels) {
        if (Object.prototype.hasOwnProperty.call(labels, key)) {
          let labelValue = labels[key];

          // Check if the labelValue is a URL and convert it to a link
          if (this.isURL(labelValue)) {
            labelValue = `<a href="${labelValue}" target="_blank">${labelValue}</a>`;
          }

          content += `<div><h4>${key}:</h4> ${labelValue}</div>`;
        }
      }

      // Add the streetview button at the end
      content += `<div>
    <button
      id="streetViewButton"
      lat="${feature.lat}"
      lng="${feature.lng}"
      class="v-btn v-btn--color-primary theme--dark v-size--small mt-4"
      style="background-color: #1976D2; color: #ffffff; border-radius: 0; padding: 7px;"
    >
      View Streetview
    </button>
  </div>`;

      // Close the main container div
      content += `</div>`;

      return content;
    },

    isURL(str) {
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return urlRegex.test(str);
    },
    updateViewDetails() {
      //PostGis docs - ST_MakeEnvelope(float xmin, float ymin, float xmax, float ymax, integer srid=unknown)
      // update view info
      if (map) {
        this.viewInfo.viewportInfo.xmin = map.getBounds().getSouthWest().lng();
        this.viewInfo.viewportInfo.ymin = map.getBounds().getSouthWest().lat();
        this.viewInfo.viewportInfo.xmax = map.getBounds().getNorthEast().lng();
        this.viewInfo.viewportInfo.ymax = map.getBounds().getNorthEast().lat();
      }

      if (this.selectedDataLevel) {
        //data level selected from dropdown
        this.setSelectedDataLevel();
      } else {
        //map zoom data level
        this.setZoomDataLevel();
      }

      // call this
      if (this.viewInfo.indicatorInfo.id) {
        this.getDataCount();
        this.getPolygonsWithDataset();
      }
    },
    setSelectedDataLevel() {
      //set data level to selected and reset flag
      if (!this.lockedView && this.selectedDataLevel) {
        this.viewInfo.viewportInfo.dataLevel = this.selectedDataLevel;
        this.selectedDataLevel = null;
      }
    },
    setZoomDataLevel() {
      if (
        //not locked & zoom is below data lowest level - set data level to lowest
        !this.lockedView &&
        this.viewInfo.indicatorInfo.lowest_show_level >
          this.zoomAreaLevels[map.getZoom()]
      ) {
        this.viewInfo.viewportInfo.dataLevel =
          this.viewInfo.indicatorInfo.lowest_show_level;
      } else {
        //not locked - set data level to zoom
        if (!this.lockedView) {
          this.viewInfo.viewportInfo.dataLevel =
            this.zoomAreaLevels[map.getZoom()];
        }
      }
    },
    mapSetup() {
      if (this.savedMapId) {
        // If loading a saved map, load the client boundary if it was on when the map was saved
        if (this.boundaryFlag) {
          this.loadClientBoundary();
        }
      } else {
        // If not loading a saved map, load default view and boundary
        this.centreToDefaultView();
        this.loadClientBoundary();
      }

      // get the service icons
      this.loadServiceTypeIcons();

      // add listener to see if anything changes
      map.addListener(
        "idle",
        () => {
          this.deBounce();
        },
        { passive: true },
      );

      // set up all the view details
      this.viewInfo.viewportInfo = {
        dataLevel: this.viewInfo.viewportInfo.dataLevel
          ? this.viewInfo.viewportInfo.dataLevel
          : 6,
        xmin: 0,
        ymin: 0,
        xmax: 0,
        ymax: 0,
        ind_id: this.viewInfo.viewportInfo.ind_id
          ? this.viewInfo.viewportInfo.ind_id
          : null,
      };

      // get the viewport to start
      this.deBounce();
    },
    centreToDefaultView() {
      // get the clients default view
      const defaultZoom = this.$store.getters.customClientConfig.default_zoom;
      const defaultLat = this.$store.getters.customClientConfig.default_lat;
      const defaultLng = this.$store.getters.customClientConfig.default_lng;

      // if they have them ALL, set the map up to that
      if (defaultZoom && defaultLat && defaultLng) {
        this.firstLoad = true;
        this.hasDefaultView = true;
        if (!isNaN(defaultLat) && !isNaN(defaultLng)) {
          map.setZoom(defaultZoom);
          map.setCenter({
            lat: parseFloat(defaultLat),
            lng: parseFloat(defaultLng),
          });
        } else {
          console.error("Invalid coordinates for default view");
        }
      }
    },
    async loadClientBoundary() {
      this.loadingBoundary = true;
      // first clear out anything that's there
      if (this.clientBoundary.length > 0) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(null);
        });

        this.clientBoundary = [];
      }

      // get the clients boundary if they have one
      if (this.$store.getters.customClientConfig.has_boundary) {
        const { Polygon } = await google.maps.importLibrary("maps");

        this.$axios
          .get("/client-boundary")
          .then(
            function (response) {
              // build and show the polygon
              if (response.data.boundary) {
                const boundary = JSON.parse(response.data.boundary);

                // build an array of polygons, can be one, or many for a multiplygon
                let polygons = [];

                if (boundary.type === "Polygon") {
                  // Polygon
                  const googleMapsPaths = boundary.coordinates.map((ring) =>
                    ring.map((coord) => {
                      return { lat: coord[1], lng: coord[0] };
                    }),
                  );

                  polygons.push(
                    new Polygon({
                      map,
                      paths: googleMapsPaths,
                      fillOpacity: 0,
                      strokeWeight: 5,
                      strokeColor: response.data.boundary_colour,
                      zIndex: 5,
                      clickable: false,
                    }),
                  );
                  this.clientBoundary = polygons;
                } else {
                  // MultiPolygon
                  const multiCoordinates = boundary.coordinates;
                  multiCoordinates.forEach((coords) => {
                    const googleMapsPaths = coords.map((coord) => {
                      return coord.map((subCoord) => {
                        return { lat: subCoord[1], lng: subCoord[0] };
                      });
                    });

                    polygons.push(
                      new Polygon({
                        map,
                        paths: googleMapsPaths,
                        fillOpacity: 0,
                        strokeWeight: 5,
                        strokeColor: response.data.boundary_colour,
                        zIndex: 5,
                        clickable: false,
                      }),
                    );
                    this.clientBoundary = polygons;
                  });
                }
              }
              this.boundaryFlag = true;
              this.loadingBoundary = false;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.loadingBoundary = false;
            }.bind(this),
          );
      }
    },
    deBounce() {
      if (this.markers.legnth > 0) {
        this.loadingServices = true;
      }
      // clear this
      clearTimeout(this.idleTimeout);
      // start the countdown again!
      this.idleTimeout = setTimeout(
        function () {
          this.updateViewDetails();
        }.bind(this),
        1250,
      );
    },
    getIndicatorDetails() {
      this.emit.emit("systemBusy", true);
      this.currentDataLevel = null;
      this.viewInfo.quintiles = {
        q1_min: "loading",
      };
      this.$axios
        .get("/standard-metadata-map/" + this.selectedIndicator)
        .then(
          function (response) {
            // handle success
            this.viewInfo.indicatorInfo = response.data[0];
            this.viewInfo.numeratorInfo = response.data[1];
            this.colourScheme = response.data[0].colour_scheme;
            this.getDataCount();
            this.getPolygonsWithDataset();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemBusy", false);
          }.bind(this),
        );
    },
    getCustomAreaPolygons(selectedCustomAreas) {
      this.emit.emit("systemBusy", true);
      this.selectedCustomAreas = [];
      this.customAreaServiceIds = [];
      this.selectedCustomAreas = [...selectedCustomAreas];
      if (selectedCustomAreas.length == 0) {
        if (this.markers.length > 0) {
          this.loadSelectedTypes();
        }
        this.emit.emit("systemBusy", false);
      } else {
        this.$axios
          .post("/custom-areas-polygons", selectedCustomAreas)
          .then(
            function (response) {
              // store the service ids for the custom areas
              const serviceIds = Object.values(response.data.service_ids);
              this.customAreaServiceIds = [
                ...this.customAreaServiceIds,
                ...serviceIds,
              ];

              //get the geojson string out as an object
              var geojson = JSON.parse(response.data.geojson);

              // if we have at least one feature...
              if (geojson.features.length > 0) {
                // turn each returned feature into a google maps object and load onto the map
                this.updatePolygonObjects(geojson.features).then(() => {
                  // zoom to the areas
                  if (!this.savedMapId) {
                    this.zoomToAreas();
                  }
                  // if we have markers loaded, rerender to filter by custom areas
                  if (this.markers.length > 0) {
                    this.loadSelectedTypes();
                  }
                });
              }
              this.emit.emit("systemBusy", false);
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemBusy", false);
            }.bind(this),
          );
      }
    },
    async updatePolygonObjects(features) {
      await this.removePolygons();
      const { Polygon } = await google.maps.importLibrary("maps");

      features.forEach((feature) => {
        const geometry = feature.geometry;
        const geometryType = geometry.type;
        const customAreaId = feature.properties.custom_area_id;

        if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
          if (geometryType === "Polygon") {
            // Polygon with possible holes
            const googleMapsPaths = geometry.coordinates.map((ring) =>
              ring.map((coord) => {
                return { lat: coord[1], lng: coord[0] };
              }),
            );

            const googleMapsPolygon = new Polygon({
              map,
              paths: googleMapsPaths,
              fillOpacity: 0,
              strokeWeight: 3,
              strokeColor: "#000000",
              zIndex: 10,
              clickable: false,
            });

            // add custom props
            googleMapsPolygon.customAreaId = customAreaId;
            this.polygonObjects.push(googleMapsPolygon);
          } else if (geometryType === "MultiPolygon") {
            // MultiPolygon
            const multiCoordinates = geometry.coordinates;
            multiCoordinates.forEach((coords) => {
              const googleMapsPaths = coords.map((coord) => {
                return coord.map((subCoord) => {
                  return { lat: subCoord[1], lng: subCoord[0] };
                });
              });

              const googleMapsPolygon = new Polygon({
                map,
                paths: googleMapsPaths,
                fillOpacity: 0,
                strokeWeight: 3,
                strokeColor: "#000000",
                zIndex: 10,
                clickable: false,
              });

              // add custom props
              googleMapsPolygon.customAreaId = customAreaId;
              this.polygonObjects.push(googleMapsPolygon);
            });
          }
        }
      });
    },
    zoomToAreas() {
      // gather up all the bounds of any loaded polys
      var bounds = new google.maps.LatLngBounds();

      // iterate over each polygon in this.polygonObjects and extend the bounds
      this.polygonObjects.forEach(function (polygon) {
        polygon.getPath().forEach(function (latLng) {
          bounds.extend(latLng);
        });
      });

      // fit the map to the bounds to show all the polygons
      map.fitBounds(bounds);
    },
    zoomToPoints() {
      // Check if there are markers to zoom to
      if (this.markers.length > 0) {
        // get the bounds of the points
        var bounds = new google.maps.LatLngBounds();

        // get the extent of visible markers
        this.markers.forEach(function (marker) {
          bounds.extend(marker.position);
        });

        // fit the map to show them
        map.fitBounds(bounds);
      }
    },
    updateSelectedIndicator(value) {
      this.selectedIndicator = value;
      this.viewInfo.viewportInfo.ind_id = value;
      if (this.viewInfo.viewportInfo.ind_id) {
        this.getIndicatorDetails();
      }
    },
    removeMarkers() {
      if (this.markerCluster && this.markers && this.markers.length > 0) {
        // Clear markers array
        this.markers = [];

        // Reset the clusterer
        if (this.markerCluster) {
          this.markerCluster.clearMarkers();
        }
      }
    },
    async removePolygons() {
      this.polygonObjects.forEach((polygon) => {
        toRaw(polygon).setMap(null);
      });
      this.polygonObjects = [];
    },
    getRandomOffset() {
      return (Math.random() - 0.5) * 0.0025;
    },
    sendAnalytics(event) {
      this.$axios.put("/send-analytics", {
        event: event,
      });
    },
  },
  watch: {
    boundaryFlag() {
      if (this.clientBoundary && !this.boundaryFlag) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(null);
        });
      } else if (this.clientBoundary && this.boundaryFlag) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(map);
        });
      }
    },
    hotspotFlag() {
      if (this.viewInfo.indicatorInfo.id) {
        this.getPolygonsWithDataset();
        this.buildIndicatorQuintiles(true);
      }
    },
    navigateResult: function () {
      map.setCenter(this.navigateResult);
      map.setZoom(13);
    },
    selectedCustomAreas: function () {
      if (this.selectedCustomAreas.length === 0) {
        this.removePolygons();
        if (this.markers.length > 0) {
          this.loadSelectedTypes();
        }
      }
    },
  },
};
</script>

<style scoped>
.mapButton {
  color: white;
  background-color: #607d8b !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mapButtons {
  position: absolute !important;
  top: 75px;
  background-color: rgba(243, 237, 237, 0);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 25px;
  gap: clamp(1px, 1vh, 10px);
}
@media only screen and (max-height: 700px) {
  .mapButton {
    width: 7vh !important;
    height: 7vh !important;
    font-size: 2vh;
  }
}
@media only screen and (min-height: 701px) {
  .mapButton {
    width: 7vh !important;
    max-width: 72px !important;
    height: 7vh !important;
    max-height: 72px !important;
    font-size: clamp(1px, 1.75vh, 18px);
  }
}

.mapLegend {
  position: absolute !important;
  top: 10px;
  right: 20px;
  margin-right: 5px;
  z-index: 1;
  width: 450px;
  max-width: 450px;
  max-height: 92vh !important;
  overflow-y: auto;
}
</style>
