/* global google */
import { Loader } from "@googlemaps/js-api-loader";

// Function to initialize the map
async function initMap(center, zoom) {
  try {
    const { Map } = await google.maps.importLibrary("maps");
    const map = new Map(document.getElementById("mapCanvas"), {
      center: center,
      zoom: zoom,
      mapId: "fee9d62b462803e",
      options: {
        gestureHandling: "greedy",
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      },
    });
    return map;
  } catch (error) {
    console.error("Error initializing Google Maps:", error);
  }
}

// Function to load the map
function loadGoogleMaps(center, zoom) {
  const key = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
  const loader = new Loader({
    apiKey: key,
    version: "weekly",
  });

  // Return the Promise from loader.load()
  return loader.load().then(() => {
    // Return the Promise from initMap
    return initMap(center, zoom);
  });
}

export { loadGoogleMaps };
